import React, { useState, memo } from 'react';
import { Box } from '@rsos/components/capstone/base';
import ThemeControl from '../../common/ThemeControl';
import CallTakerModalsContainer from './CallTakerMode/Modals/CallTakerModalsContainer';
import Settings from './Settings/index';

const SettingsControl = memo(({ path }) => {
  const [isCTMClicked, setIsCTMClicked] = useState(false);

  const isEmergencyData =
    path === '/home' ||
    path === '/irp-home' ||
    path === '/query-interface' ||
    path === '/irp-query-interface';
  return (
    <Box horizontal>
      {isEmergencyData ? (
        <>
          <Settings path={path} setIsCTMClicked={setIsCTMClicked} />
          <CallTakerModalsContainer
            isCTMClicked={isCTMClicked}
            setIsCTMClicked={setIsCTMClicked}
          />
        </>
      ) : (
        <ThemeControl path={path} data-name="navigation-theme-control" />
      )}
    </Box>
  );
});

export default SettingsControl;
