import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getMostRecentLoc,
  getMostRecentLocations,
} from 'emergency_data/src/utils/getMostRecentLoc';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { APPLICATION_AGENT_511 } from '@rsos/constants/locationApplications';
import { PHASE, ALI_UNDERSCORE } from '@rsos/constants/locationTypes';
import normalizeError from '@rsos/sinatra/src/utils/normalizeError';
import { calcDistance } from '@rsos/utils';
import { getCallQueueMaxAge } from '@rsos/utils/metaTags';
import { captureExceptionWithScope } from '@rsos/utils/sentry';
import { MW_5_10_0 } from '../../constants/migrationVersions';
import { runMigration } from '../../migrations';
import { DEVICE_MEDIA } from '../media/mediaSlice';
import {
  postShareEmergencyCall,
  postAgent511Application,
} from './emergencyCallsAPI';
import { shouldUpdateCallStatus } from './helpers';

// TODO: update getEmergencyCall from the emergency_data application
// export const getEmergencyCall = createAsyncThunk(
//   'emergencyCalls/getEmergencyCall',
//   async () => {},
// );

/**
 * agency share/chat:
 * share an emergency call with a linked agency
 */
export const shareEmergencyCall = createAsyncThunk(
  'emergencyCalls/shareEmergencyCall',
  async (data, { rejectWithValue }) => {
    try {
      const response = await postShareEmergencyCall(data);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response?.data,
      });
    }
  },
);

/**
 * Text-to-911:
 * Launch Agent511 Textblue application
 */
export const launchAgent511Application = createAsyncThunk(
  'emergencyCalls/launchAgent511Application',
  async (data, { rejectWithValue }) => {
    try {
      const response = await postAgent511Application(data);
      return response.data;
    } catch (error) {
      const { message } = normalizeError(error);
      return rejectWithValue(message);
    }
  },
);

// normalize additional keys for agency share/chat
const agencyShareChatKeys = {
  callSharer: null,
  chatParticipants: [],
  conversationID: null,
  isCallShared: false,
  isCallReceived: false,
  isUnread: false,
  isUnseen: false,
  sharedWithAgencies: [],
};

const maxCallQueueAge = getCallQueueMaxAge() * 1000;

/**
 * calculate the time difference in seconds between two timestamps
 *
 * @param {date} newTime - new timestamp
 * @param {date} prevTime - previous timestamp
 */
const calcTimeDifference = (newTime, prevTime) => {
  return moment(newTime).diff(moment(prevTime), 'seconds');
};

/**
 * compare the uncertainty radius of two locations to determine
 * if the new location's uncertainty radius is within 30% of the previous
 * location's uncertainty radius
 *
 * @param {number} newRadius - uncertainty_radius of new location
 * @param {number} prevRadius - uncertainty_radius of previous location
 */
const compareUncertaintyRadius = (newRadius, prevRadius) => {
  return !(newRadius > prevRadius * 1.3 || newRadius < prevRadius * 0.7);
};

/**
 * Updates queue for MedicAlert candidates data for a given phone number
 * @param {Object} callQueue - The existing `callQueue`
 * @param {Object} action - payload
 */
const handleCandidates = (callQueue, action) => {
  const { candidates, id } = action;
  // If there is no id, return the callQueue as is, since the data doesn't have
  // an associated caller ID to map the data to.
  if (!id) {
    return callQueue;
  }
  let callerID = id;
  if (callerID.length === 10) {
    callerID = `1${callerID}`;
  }

  // Check if the candidates array is empty to prevent overwriting of valid data
  if (!candidates.length) {
    return { ...callQueue };
  }
  const sourceData = {
    medicalert: {
      candidates,
    },
  };

  return { ...getNewQueue(callQueue, sourceData, 'adr', callerID) };
};

/**
 * Updates queue for MedicAlert profile data for a given phone number
 * @param {Object} callQueue - The existing `callQueue`
 * @param {Object} action - payload
 */
const handleProfile = (callQueue, action) => {
  const { id, mafid, profile } = action;
  let callerID = id;
  // If there is no id, return the callQueue as is, since the data doesn't have
  // an associated caller ID to map the data to.
  if (!id) {
    return callQueue;
  }
  if (callerID.length === 10) {
    callerID = `1${callerID}`;
  }

  const sourceData = {
    medicalert: {
      profiles: {
        [mafid]: profile,
      },
    },
  };

  return { ...getNewQueue(callQueue, sourceData, 'adr', callerID) };
};

/**
 * Updates callQueue[callerID].additionalData for the SAData for RAD E requests
 * @param {Object} callQueue - The existing `callQueue`
 * @param {Object} data - The payload`
 */
const handleEmergencySessionData = (callQueue, data) => {
  const { id: callerID, payload } = data;
  const IGNORED_ORGS = ['911_inform', DEVICE_MEDIA];
  let newData = {};
  for (const org in payload) {
    if (IGNORED_ORGS.includes(org)) {
      continue;
    }
    const orgPayload = { ...payload[org].data };

    // Handle data to match ADD_SAD
    for (const keyName in orgPayload) {
      const orgData = orgPayload[keyName];
      // Handle display schema if it exists
      const metaData = payload[org].meta[keyName];
      let displaySchema = null;

      if (metaData?.display_schema) {
        displaySchema = { ...metaData.display_schema };
      }

      const getDataCategory = () => {
        const isGoogle = org === 'google';
        // Google receives special format for google adr from 3.9.0
        // android_phone is the suffix while
        // caller_info and crash_detection from RAD but
        // caller_info and car_crash from Hermes
        // "google": {
        //   "data": {
        //     "android_phone": {
        //       "caller_info": {
        //         "device_language": "Spanish"
        //       },
        //       "crash_detection": {
        //         "adr_carcrash_time": 1596157331886
        //       }
        //     }
        //   }
        // }
        if (isGoogle) {
          return Object.keys(orgData).includes('crash_detection')
            ? 'car_crash'
            : 'caller_info';
        } else {
          // Emergency Session/RAD E requests don't contain a dataCategory that
          // maps to a corresponding message from Hermes
          return '';
        }
      };

      newData = {
        ...newData,
        [org]: [
          ...(newData[org] ? newData[org].map(dataSet => dataSet) : []),
          {
            createdTime: new Date().getTime(),
            data: orgData,
            ...(metaData?.display_schema_url
              ? { display_schema_url: metaData?.display_schema_url }
              : {}),
            ...(displaySchema ? { display_schema: displaySchema } : {}),
            dataCategory: getDataCategory(),
            dataSuffix: keyName,
          },
        ],
      };
    }
  }
  return { ...getNewQueue(callQueue, newData, 'adr', callerID) };
};

/**
 * Updates callQueue[callerID].additionalData for the SAData
 * @param {Object} callQueue - The existing `callQueue`
 * @param {Object} message - message received from Hermes for the SAData
 */
const handleSAData = (callQueue, message) => {
  let callerID;

  try {
    callerID = message.topic.split('//')[1];
  } catch (error) {
    callerID = message.topic;
    captureExceptionWithScope(new Error('Error parsing topic'), {
      topic: message.topic,
      error,
    });
  }

  // When parent_uri is present, its a subset of a bigger dataset. Save it
  // as part of the parent data instead - introduced by Project Waterloo.
  if (message && !!message.parent_uri) {
    try {
      callerID = message.parent_uri.split('//')[1];
    } catch (error) {
      captureExceptionWithScope(new Error('Error parsing parent_uri'), {
        parent_uri: message.parent_uri,
        error,
      });
    }
  }

  const { category, organization, suffix } = parseWSMessage(message);

  const orgData = {
    createdTime: message.created_time,
    data: message.body,
    display_schema_url: message.display_schema_url,
    display_schema: message.display_schema,
    dataCategory: category,
    dataSuffix: suffix,
  };

  const sourceData = {
    [organization]: [orgData],
  };

  return { ...getNewQueue(callQueue, sourceData, 'adr', callerID) };
};

/**
 * Updates callQueue[callerID].additionalData for the ADR. This is similar to
 * handleSAData, with the exception of the callerID parsing
 * @param {Object} callQueue - The existing `callQueue`
 * @param {Object} message - message received from Hermes for the SAData
 */
const handleAdrQueryData = (callQueue, message) => {
  let callerID;
  try {
    callerID = message.topic.split('//')[1].split('-adr')[0];
  } catch (error) {
    callerID = message.topic;
    captureExceptionWithScope(new Error('Error parsing topic'), {
      topic: message.topic,
      error,
    });
  }

  // When parent_uri is present, its a subset of a bigger dataset. Save it
  // as part of the parent data instead - introduced by Project Waterloo.
  if (message && !!message.parent_uri) {
    try {
      callerID = message.parent_uri.split('//')[1].split('-adr')[0];
    } catch (error) {
      captureExceptionWithScope(new Error('Error parsing parent_uri'), {
        parent_uri: message.parent_uri,
        error,
      });
    }
  }

  const { category, organization, suffix } = parseWSMessage(message);

  const orgData = {
    createdTime: message.created_time,
    data: message.body,
    display_schema_url: message.display_schema_url,
    display_schema: message.display_schema,
    dataCategory: category,
    dataSuffix: suffix,
  };

  const sourceData = {
    [organization]: [orgData],
  };

  return { ...getNewQueue(callQueue, sourceData, 'adr', callerID) };
};

/**
 * MC-15067
 * check the location data for the same calls that are close together.
 * hide the location if all of the criteria below are met:
 * - new location's create time is less than 30 seconds
 * - new location's distance is within 5 meters
 * - new location's uncertainty radius differs from the previous by 30%
 *
 * @param {Object} newLocation - new location object
 * @param {Object} prevLocation - previous location object
 */
const hideNearbyLocations = (newLocation, prevLocation) => {
  const newTime = newLocation.created_time,
    prevTime = prevLocation.created_time;
  return (
    newTime === prevTime ||
    (newTime &&
      prevTime &&
      calcTimeDifference(newTime, prevTime) < 30 &&
      calcDistance(newLocation, prevLocation) <= 5 &&
      compareUncertaintyRadius(
        newLocation.uncertainty_radius,
        prevLocation.uncertainty_radius,
      ))
  );
};

const addWorkstationPosition = (callerID, callQueue, data) => {
  const workstationPositions = callQueue[callerID].workstationPositions;
  const workstationPositionsLen = workstationPositions.length;
  if (workstationPositionsLen) {
    if (
      !workstationPositions[workstationPositionsLen - 1] &&
      !data.workstation_position
    ) {
      return callQueue[callerID].workstationPositions;
    } else {
      return [
        ...callQueue[callerID].workstationPositions,
        data.workstation_position || null,
      ];
    }
  } else {
    return [data.workstation_position || null];
  }
};

/**
 * Updates the existing `callQueue` based on incoming location
 * @param {Object} callQueue - The existing `callQueue`
 * @param {Object} data - Location object or additional data object
 * @param {String} dataType - 'location' or 'adr'. Determines whether to update
 * `core` or `additionalData`
 * @param {String} callerID
 */
const getNewQueue = (
  callQueue,
  data,
  dataType = 'location',
  callerID,
  isManualSearch = false,
) => {
  // TODOv1: This causes the state to be updated regardless of if the
  // location passes the hideNearbyLocations() check because the `expiredTime`
  // still gets updated. It has the unintentional side effect of causing the
  // markers to rerender on the map. See comment in Markers.js for the hook
  // that watches for markers and markerCircles
  const nowTS = Math.round(new Date().getTime());
  const numExists = callQueue.hasOwnProperty(callerID);

  // Determines if the event is a text-to-911 request. If the event's source transitions from a
  // `call` to `sms` and vice versa, continue to treat the event as a text-to-911 event.
  const isTT911ADR = Object.keys(data).includes(APPLICATION_AGENT_511);
  const isTT911Location = data.application === APPLICATION_AGENT_511;

  // Show new call notification only applies to TT911 requests for now, but in the future it could
  // expand to other features and/or conditions.
  const showNewCallNotification = isTT911ADR || isTT911Location;

  const isAniAli =
    data?.application && data?.application?.startsWith(ALI_UNDERSCORE);

  const isSecondary =
    data?.class_of_service_category &&
    data?.class_of_service_category.startsWith(PHASE);

  // getMostRecentLoc function returns the most recent primary or secondary location.
  const mostRecentLocation = getMostRecentLoc(callQueue[callerID]);

  if (!numExists) {
    if (isAniAli && isSecondary) {
      return {
        ...callQueue,
        [callerID]: {
          core: {
            locations: [],
            secondaryLocations: [data],
          },
          additionalData: {},
          expiredTime: nowTS + maxCallQueueAge,
          isManualSearch,
          isTT911: isTT911Location,
          showNewCallNotification,
          agencyShareChat: {
            ...agencyShareChatKeys,
          },
          callStatuses: data?.call_status ? [data.call_status] : [],
          workstationPositions: data?.workstation_position
            ? [data.workstation_position]
            : [],
          adrQuery: {
            loading: false,
            fetched: false,
          },
        },
      };
    }

    if (dataType === 'adr') {
      return {
        ...callQueue,
        [callerID]: {
          core: {
            locations: [],
            secondaryLocations: [],
          },
          additionalData: { ...data },
          expiredTime: nowTS + maxCallQueueAge,
          isTT911: isTT911ADR,
          showNewCallNotification,
          callStatuses: [],
          workstationPositions: [],
          adrQuery: {
            hasData: false,
            loading: false,
            fetched: false,
          },
        },
      };
    }

    return {
      ...callQueue,
      [callerID]: {
        core: {
          locations: [data],
          secondaryLocations: [],
        },
        additionalData: {},
        expiredTime: nowTS + maxCallQueueAge,
        isManualSearch,
        isTT911: isTT911Location,
        showNewCallNotification,
        agencyShareChat: {
          ...agencyShareChatKeys,
        },
        // Possible call status values are: callQueued, callAnswered, holdCall, and endCall.
        callStatuses: data?.call_status ? [data.call_status] : [],
        workstationPositions: data?.workstation_position
          ? [data.workstation_position]
          : [],
        // moved out of `core` since ADR also needs to be handled
        adrQuery: {
          hasData: false,
          loading: false,
          fetched: false,
        },
      },
    };
    // Should not need to use `numberWithNewLocation` to handle markers,
    // since new marker handling is based off of the `incidents` prop.
    // Handling for the `incidents` value should address that problem with
    // finding the relevant markers
  } else {
    if (!isSecondary) {
      if (dataType === 'adr') {
        // The only key in the data is the organization name
        // e.g { medicalert: { data, ... } }
        // Assume there is only one key in `data` since incoming messages from
        // Hermes pertain to one set of data.
        const orgName = Object.keys(data)[0];
        // Check if data from the source exists before updating. Such as the
        // MedicAlert scenario where there can be candidates and profile data
        const orgData = callQueue[callerID].additionalData[orgName];
        // Treat RAD data differently since the data isn't passed to Ellington.
        if (orgName === 'rad') {
          if (isEqual(data.rad, orgData)) {
            return { ...callQueue };
          }
          return {
            ...callQueue,
            [callerID]: {
              ...callQueue[callerID],
              additionalData: {
                ...callQueue[callerID].additionalData,
                rad: data.rad,
              },
            },
          };
        }

        if (orgData) {
          const newData = data[orgName][0]; // Assume 1 entry for incoming SAData
          // Using the data_type's suffix as the unique identifier, check if an
          // entry with it already exists
          const foundIndex = orgData.findIndex(
            existingData => existingData.dataSuffix === newData.dataSuffix,
          );
          if (foundIndex < 0) {
            // New data
            return {
              ...callQueue,
              [callerID]: {
                ...callQueue[callerID],
                additionalData: {
                  ...callQueue[callerID].additionalData,
                  [orgName]: [
                    ...callQueue[callerID].additionalData[orgName],
                    ...[newData],
                  ],
                },
              },
            };
          } else {
            // Cannot accurately compare the the existing data with new data
            // because if the data contains timestamps such as when the data was
            // created, it will be considered different, which may result in cards
            // rendering duplicate data. Just replace the entry with the matching
            // suffix with the new data
            const newOrgData = callQueue[callerID].additionalData[orgName].map(
              (item, idx) => {
                if (idx === foundIndex) {
                  // If newOrgData.data contains data/isn't `null`, replace the
                  // existing data. See MC-18381
                  if (newData.data) {
                    // Agent511 is guaranteed to send an url for the initial text-to-911 payload
                    // but not guaranteed for subsequent payloads. If newData.data is missing the url
                    // field, use the url from orgData[idx].data.url as it should exist.
                    if (
                      orgName === APPLICATION_AGENT_511 &&
                      !newData.data.url
                    ) {
                      return {
                        ...newData,
                        data: {
                          ...newData.data,
                          url: orgData[idx].data.url,
                        },
                      };
                    }
                    return newData;
                  }
                }
                return item;
              },
            );
            return {
              ...callQueue,
              [callerID]: {
                ...callQueue[callerID],
                additionalData: {
                  ...callQueue[callerID].additionalData,
                  [orgName]: [...newOrgData],
                },
              },
            };
          }
        }

        return {
          ...callQueue,
          [callerID]: {
            ...callQueue[callerID],
            additionalData: {
              ...callQueue[callerID].additionalData,
              ...data,
            },
            showNewCallNotification: showNewCallNotification,
            // Do not update expiredTime. That is driven by the call itself
          },
        };
      }

      let filteredLiveLocations;
      const currentCall = callQueue[callerID];
      const currentCallLocations = callQueue[callerID].core.locations;
      const isTT911 =
        currentCall.isTT911 || data.application === APPLICATION_AGENT_511;
      const {
        mostRecentLocation: mostRecentPrimaryLocation,
      } = getMostRecentLocations(currentCall);

      // If a previous call location was shared with its peer agencies, any subsequent location
      // updates should check for the props `isCallReceived` and `isCallShared` to ensure the
      // corresponding call display the correct map marker
      if (currentCall?.agencyShareChat?.isCallReceived)
        data.agencyShareChat.isCallReceived = true;
      if (currentCall?.agencyShareChat?.isCallShared)
        data.agencyShareChat.isCallShared = true;

      if (currentCallLocations.length > 0) {
        const prevLoc = mostRecentPrimaryLocation;
        if (hideNearbyLocations(data, prevLoc)) {
          filteredLiveLocations = [...currentCallLocations];
        } else {
          filteredLiveLocations = [...currentCallLocations, data];
        }
      } else {
        // Assume there is additional data, but no locations
        filteredLiveLocations = [data];
      }

      // If the event was previously a text-to-911 request, continue to treat the event as a
      // text-to-911 request. This does not overwrite the event's location data, only updates the
      // `isTT911` field to ensure the correct icon and marker is displayed in the queue/map.
      if (isTT911) {
        callQueue[callerID].isTT911 = true;
        filteredLiveLocations[filteredLiveLocations?.length - 1].isTT911 = true; // Identifier for the map marker
      }
      // TODO: does this affect the order of `emergencyCalls`?
      return {
        ...callQueue,
        [callerID]: {
          ...callQueue[callerID],
          core: {
            ...callQueue[callerID].core,
            locations: filteredLiveLocations,
          },
          expiredTime: nowTS + maxCallQueueAge,
          isManualSearch,
          callStatuses: shouldUpdateCallStatus(mostRecentLocation, data)
            ? [...callQueue[callerID].callStatuses, data.call_status]
            : [...callQueue[callerID].callStatuses],
          workstationPositions: addWorkstationPosition(
            callerID,
            callQueue,
            data,
          ),
        },
      };
    } else if (isSecondary) {
      let secondaryLocations = callQueue[callerID].core.secondaryLocations;

      if (isAniAli && isSecondary) {
        secondaryLocations = [...secondaryLocations, data];
      }
      return {
        ...callQueue,
        [callerID]: {
          ...callQueue[callerID],
          core: {
            ...callQueue[callerID].core,
            secondaryLocations,
          },
          expiredTime: nowTS + maxCallQueueAge,
          isManualSearch,
          callStatuses: shouldUpdateCallStatus(mostRecentLocation, data)
            ? [...callQueue[callerID].callStatuses, data.call_status]
            : [...callQueue[callerID].callStatuses],
          workstationPositions: addWorkstationPosition(
            callerID,
            callQueue,
            data,
          ),
        },
      };
    }
  }
};

/**
 * Checks if the Queue should add a call when a call comes in
 * @param {Object} call - The new call coming in `call`
 * @param {Array} removedCalls - A list of calls that have been removed
 */
const shouldAddToQueue = (call, removedCalls) => {
  let id = call.caller_id;
  const nowTS = Math.round(new Date().getTime());
  let removeID = removedCalls.find(i => {
    let setTime = (nowTS - i.call_start_time) / 1000;
    return (
      i.caller_id === id &&
      i.call_start_time === call.call_start_time &&
      setTime > 7200
    );
  });

  if (removeID) {
    if (removeID.call_start_time === call.call_start_time) {
      return false;
    }
  }

  return true;
};

/**
 * Copied from emergency_data/src/utils/wsMessage
 * Parse message.type and return it as an object
 * message.type has to be in type.category.organization.suffix
 * while suffix is be free-form
 * @param {Object} message - message from Hermes
 */
export const parseWSMessage = message => {
  const messageArray = message.type.split('.');
  const messageType = messageArray[0].toLowerCase();
  let messageCategory, messageOrganization, suffixIndex, messageSuffix;

  if (messageArray[1]) messageCategory = messageArray[1].toLowerCase();
  if (messageArray[2]) messageOrganization = messageArray[2].toLowerCase();
  if (messageOrganization)
    suffixIndex =
      message.type.indexOf(messageArray[2]) + messageOrganization.length + 1;

  if (suffixIndex)
    messageSuffix = message.type.substring(suffixIndex).toLowerCase();

  return {
    type: messageType,
    category: messageCategory,
    organization: messageOrganization,
    suffix: messageSuffix,
  };
};

export const initialState = {
  agencyShareChat: {
    toastReceived: {
      callSource: null,
      sharerAgencyName: null,
      receivedCallerID: null,
      isToastVisible: false,
    },
    unreadCalls: [],
    unseenCalls: [],
  },
  callQueue: {},
  isJVHermesInitialized: false,
  isReverseGeocoding: false,
  loading: {
    shareEmergencyCall: false,
    launchAgent511Application: false,
  },
  locationError: null,
  locationType: 'call',
  numberWithNewLocation: null,
  numNotifications: 0,
  removedCalls: [],
  selectedCallerID: null,
  viewNumLocations: 'all',
  tt911Sounds: {},
};

const emergencyCallsSlice = createSlice({
  name: 'emergencyCalls',
  initialState,
  reducers: {
    addLocation: (state, action) => {
      // TODOv3 prioritize: can combine with ADD_LOCATION_TO_QUEUE. need to update Hermes.js. the `locationType` handled differently from ADD_LOCATION_TO_QUEUE. needs consolidating
      // for handling the messages
      // These locations are coming in through Hermes. the `isManualSearch` arg should be false
      const newQueue = getNewQueue(
        state.callQueue,
        action.payload,
        'location',
        action.payload.caller_id,
        false,
      );
      state.numberWithNewLocation = action.payload.caller_id;
      if (!isEqual(newQueue, state.callQueue)) {
        state.callQueue = newQueue;
        state.numberWithNewLocation = action.payload.caller_id;
        if (action?.locationType) {
          state.locationType = action.payload.source;
        }
      }
    },
    removeAllCalls: state => {
      for (const prop in state.callQueue) {
        delete state.callQueue[prop];
      }
      state.agencyShareChat.unreadCalls = [];
      state.agencyShareChat.unseenCalls = [];
      Object.entries(state.tt911Sounds).forEach(([key, value]) => {
        if (value) {
          clearInterval(value.interval);
        }
      });
      state.tt911Sounds = {};
    },
    removeCall: (state, action) => {
      delete state.callQueue[action.payload];
      state.agencyShareChat.unreadCalls = state.agencyShareChat.unreadCalls.filter(
        call =>
          call.core.locations[call.core.locations?.length - 1].caller_id !==
          action.payload,
      );
      state.agencyShareChat.unseenCalls = state.agencyShareChat.unseenCalls.filter(
        call =>
          call.core.locations[call.core.locations?.length - 1].caller_id !==
          action.payload,
      );
      clearInterval(state.tt911Sounds[action.payload]?.interval);
    },
    inactiveCalls: (state, action) => {
      if (typeof action.payload === 'object' && action.payload !== null) {
        state.removedCalls.push(action.payload);
      }
    },
    resetNumberWithNewLocation: state => {
      state.numberWithNewLocation = null;
    },
    setRgAddress: (state, action) => {
      const {
        address,
        addressObject,
        callerID,
        isSecondaryLocation,
        locationIndex,
        rgAddressProvider,
      } = action.payload;

      // isSecondaryLocation is true when the reverse geocoded secondary location does not have an
      // address_single_line, then it uses the rgAddress instead.
      if (
        isSecondaryLocation &&
        state.callQueue[callerID].core.secondaryLocations[locationIndex]
      ) {
        state.callQueue[callerID].core.secondaryLocations[locationIndex] = {
          ...state.callQueue[callerID].core.secondaryLocations[locationIndex],
          rgAddress: address,
          addressObject,
          rgAddressProvider,
        };
      }
      // Add rgAddress and addressObject to the primary (ELS) location if it exists.
      else if (state.callQueue[callerID].core.locations[locationIndex]) {
        state.callQueue[callerID].core.locations[locationIndex] = {
          ...state.callQueue[callerID].core.locations[locationIndex],
          rgAddress: address,
          addressObject,
          rgAddressProvider,
        };
      }
      // Add rgAddress and addressObject to the secondary (ANI/ALI) location if there is no primary
      // (ELS) location for the 911 event, this is an unlikely scenario.
      else if (
        state.callQueue[callerID].core.secondaryLocations[locationIndex]
      ) {
        state.callQueue[callerID].core.secondaryLocations[locationIndex] = {
          ...state.callQueue[callerID].core.secondaryLocations[locationIndex],
          rgAddress: address,
          addressObject,
          rgAddressProvider,
        };
      }
    },
    setSelectedCallerID: (state, action) => {
      const nowTS = Math.round(new Date().getTime());
      const callerID = action.payload;
      state.selectedCallerID = callerID;
      // This check is to prevent crashes in QV when resetting the caller ID on
      // initial page load and clearing all data
      if (state.callQueue[callerID] && state.callQueue[callerID].expiredTime) {
        state.callQueue[callerID].expiredTime = nowTS + maxCallQueueAge;
      }
      if (state.callQueue[callerID]?.agencyShareChat?.isUnseen) {
        state.callQueue[callerID].agencyShareChat.isUnseen = false;
        state.callQueue[callerID].core.locations[
          state.callQueue[callerID].core.locations?.length - 1
        ].agencyShareChat.isUnseen = false;
      }
      if (state.agencyShareChat.unseenCalls.length) {
        state.agencyShareChat.unseenCalls = state.agencyShareChat.unseenCalls.filter(
          unseenCall => unseenCall.core.locations[0].caller_id !== callerID,
        );
      }
      if (state.callQueue[callerID]?.showNewCallNotification) {
        state.callQueue[callerID].showNewCallNotification = false;
      }
    },
    viewAllCalls: state => {
      state.numberWithNewLocation = null;
      state.selectedCallerID = null;
    },
    setAgencyShareChatToastReceived: (state, action) => {
      const {
        callSource,
        sharerAgencyName,
        receivedCallerID,
        isToastVisible,
      } = action.payload;
      state.agencyShareChat.toastReceived = {
        ...state.agencyShareChat.toastReceived,
        callSource,
        isToastVisible,
        receivedCallerID,
        sharerAgencyName,
      };
    },
    setCallReceived: (state, action) => {
      const {
        additionalData,
        callerID,
        callSharer,
        chatParticipants,
        conversationID,
      } = action.payload;
      state.callQueue[callerID] = {
        ...state.callQueue[callerID],
        additionalData,
        agencyShareChat: {
          ...state.callQueue[callerID]?.agencyShareChat,
          callSharer,
          chatParticipants,
          conversationID,
          isCallReceived: true,
          isUnseen: true,
        },
      };
      state.callQueue[callerID].core.locations[
        state.callQueue[callerID].core.locations?.length - 1
      ].agencyShareChat = {
        ...state.callQueue[callerID].core.locations[
          state.callQueue[callerID].core.locations?.length - 1
        ]?.agencyShareChat,
        isCallReceived: true,
        isUnseen: true,
      };
      if (!state.agencyShareChat.unseenCalls.includes(callerID)) {
        state.agencyShareChat.unseenCalls = [
          ...state.agencyShareChat.unseenCalls,
          state.callQueue[callerID],
        ];
      }
    },
    setCallShared: (state, action) => {
      const { callerID, chatParticipants, conversationID } = action.payload;
      state.callQueue[callerID] = {
        ...state.callQueue[callerID],
        agencyShareChat: {
          ...state.callQueue[callerID]?.agencyShareChat,
          chatParticipants,
          conversationID,
          isCallShared: true,
        },
      };
      state.callQueue[callerID].core.locations[
        state.callQueue[callerID].core.locations?.length - 1
      ].agencyShareChat = {
        ...state.callQueue[callerID].core.locations[
          state.callQueue[callerID].core.locations?.length - 1
        ]?.agencyShareChat,
        isCallShared: true,
      };
    },
    setSharedWithAgencies: (state, action) => {
      const {
        accountIDs,
        callerID,
        chatParticipants,
        conversationID,
      } = action.payload;
      state.callQueue[callerID] = {
        ...state.callQueue[callerID],
        agencyShareChat: {
          ...state.callQueue[callerID]?.agencyShareChat,
          chatParticipants,
          conversationID,
          isCallShared: true,
          sharedWithAgencies: [...accountIDs],
        },
      };
      state.callQueue[callerID].core.locations[
        state.callQueue[callerID].core.locations?.length - 1
      ].agencyShareChat = {
        ...state.callQueue[callerID].core.locations[
          state.callQueue[callerID].core.locations?.length - 1
        ]?.agencyShareChat,
        isCallShared: true,
      };
    },
    setCallRead: (state, action) => {
      const callerID = action.payload;
      if (
        state.callQueue[callerID] &&
        state.selectedCallerID === callerID &&
        state.callQueue[callerID]?.agencyShareChat?.isUnread
      ) {
        state.callQueue[callerID].agencyShareChat.isUnread = false;

        const mostRecentLocation =
          state.callQueue[callerID].core.locations[
            state.callQueue[callerID].core.locations?.length - 1
          ]?.agencyShareChat;
        if (mostRecentLocation?.isUnread) mostRecentLocation.isUnread = false;

        state.agencyShareChat.unreadCalls = state.agencyShareChat.unreadCalls.filter(
          call =>
            call.core.locations[call.core.locations?.length - 1].caller_id !==
            callerID,
        );
      }
    },
    setCallUnread: (state, action) => {
      // deep clone the unreadCalls array
      const unreadCalls = JSON.parse(JSON.stringify(action.payload));

      unreadCalls.forEach(call => {
        const callerID =
          call.core.locations[call.core.locations?.length - 1].caller_id;
        if (state.selectedCallerID !== callerID) {
          // this sets the call in the queue as unread
          state.callQueue[callerID].agencyShareChat.isUnread = true;

          // this sets the marker on the map as unread
          // markers use both `currentIncidents` & `unreadCalls`
          const locationsLength =
            state.callQueue[callerID]?.core?.locations?.length;
          if (locationsLength) {
            state.callQueue[callerID].core.locations[locationsLength - 1] = {
              ...state.callQueue[callerID].core.locations[
                state.callQueue[callerID].core.locations?.length - 1
              ],
              agencyShareChat: {
                ...state.callQueue[callerID].core.locations[
                  state.callQueue[callerID].core.locations?.length - 1
                ]?.agencyShareChat,
                isUnread: true,
              },
            };
          }

          // markers rely on the `state.agencyShareChat.unreadCalls` state to determine if it
          // should be unread or not
          call.agencyShareChat.isUnread = true;
        }
      });
      state.agencyShareChat.unreadCalls = unreadCalls;
    },
    resetUnreadCalls: state => {
      state.agencyShareChat.unreadCalls = [];
    },
    getNumNotifications: state => {
      let totalNotifications = 0;
      if (Object.keys(state.callQueue).length) {
        Object.values(state.callQueue).forEach(call => {
          let notification = 0;
          if (
            (call?.showNewCallNotification && call.core.locations.length) ||
            call?.agencyShareChat?.isUnread ||
            call?.agencyShareChat?.isUnseen
          ) {
            notification = 1;
          }
          totalNotifications += notification;
        });
      }
      state.numNotifications = totalNotifications;
    },
    setCallChatParticipants: (state, action) => {
      const { callerID, chatParticipants } = action.payload;
      if (state.callQueue[callerID]) {
        state.callQueue[
          callerID
        ].agencyShareChat.chatParticipants = chatParticipants;
      }
    },
    setCallExpired: (state, action) => {
      const { callerID, isCallExpired } = action.payload;
      if (state.callQueue[callerID]) {
        state.callQueue[callerID].isCallExpired = isCallExpired;
      }
    },
    setTT911Sounds: (state, action) => {
      const { phoneNumber, data } = action.payload;
      state.tt911Sounds = {
        ...state.tt911Sounds,
        [phoneNumber]: { ...state.tt911Sounds[phoneNumber], ...data },
      };
    },
    setAdrQueryStatus: (state, action) => {
      // This is intended be used on the initial button click and 20 seconds
      // later if no data comes through the websocket
      const { callerID, loading, fetched } = action.payload;
      state.callQueue[callerID] = {
        ...state.callQueue[callerID],
        adrQuery: {
          ...state.callQueue[callerID].adrQuery,
          loading,
          fetched,
        },
      };
    },
    setAdrQueryData: (state, action) => {
      const callerID = action.payload.topic.split('tel://')[1].split('-adr')[0];
      const org = action.payload.type.split('.')[2].toLowerCase();
      state.callQueue = handleAdrQueryData(state.callQueue, action.payload);
      if (org === 'apple') {
        state.callQueue[callerID] = {
          ...state.callQueue[callerID],
          adrQuery: {
            ...state.callQueue[callerID].adrQuery,
            loading: false,
            fetched: true,
            hasData: !!action.payload.body && !!action.payload.display_schema,
          },
        };
      }
    },
  },
  extraReducers: {
    // TODO: update pending, rejected, and fulfilled states from the emergency_data application
    // [getEmergencyCall.pending]: () => {},
    // [getEmergencyCall.rejected]: () => {},
    // [getEmergencyCall.fulfilled]: () => {},
    [shareEmergencyCall.pending]: state => {
      state.loading.shareEmergencyCall = true;
    },
    [shareEmergencyCall.rejected]: state => {
      state.loading.shareEmergencyCall = false;
    },
    [shareEmergencyCall.fulfilled]: state => {
      state.loading.shareEmergencyCall = false;
    },
    [launchAgent511Application.pending]: state => {
      state.loading.launchAgent511Application = true;
    },
    [launchAgent511Application.rejected]: state => {
      state.loading.launchAgent511Application = false;
    },
    [launchAgent511Application.fulfilled]: state => {
      state.loading.launchAgent511Application = false;
    },
    ['ADD_LOCATION_TO_QUEUE']: (state, action) => {
      // TODOv2: update the handling of locations within `reducers`.
      // Note: use extraReducers to handle this for now as incoming locations
      // are reverse geocoded before being handled by redux. This reduces the
      // need to handle locations differently to use slices.
      // TODO: the action creator `addLocToQueue` is including unnecessary maxCallQueueAge, and maxCallQueueSize
      if (!shouldAddToQueue(action.loc, state.removedCalls)) {
        return;
      }
      const newQueue = getNewQueue(
        state.callQueue,
        action.loc,
        'location',
        action.loc.caller_id,
        false,
      );
      if (!isEqual(newQueue, state.callQueue)) {
        state.callQueue = newQueue;
        state.numberWithNewLocation = action.loc.caller_id;
        if (action?.locationType) state.locationType = action.locationType;
      }
    },
    ['ADD_SAD']: (state, action) => {
      state.callQueue = handleSAData(state.callQueue, action.message);
    },
    ['SET_CANDIDATES']: (state, action) => {
      state.callQueue = handleCandidates(state.callQueue, action);
    },
    ['SET_PROFILE']: (state, action) => {
      state.callQueue = handleProfile(state.callQueue, action);
    },
    // eslint-disable-next-line no-unused-vars
    ['FORCED_LOGOUT_SUCCESS']: state => {
      state = initialState;
    },
    // eslint-disable-next-line no-unused-vars
    ['LOGOUT_SUCCESS']: state => {
      state = initialState;
    },
    // eslint-disable-next-line no-unused-vars
    ['SINATRA_LOGOUT']: state => {
      state = initialState;
    },
    ['SET_INITIALIZED_HERMES_STATE']: (state, action) => {
      state.isJVHermesInitialized = action.bool;
    },
    // TODO: these can be updated to use createAsyncThunk. components that use
    //  this will need updating. see `getEmergencyCall` above
    ['FETCH_LOCATION_FAILED']: (state, action) => {
      state.locationError = action.error;
    },
    ['FETCH_LOCATION_SUCCESS']: (state, action) => {
      // This case occurs when a user does a manual location query
      const callerID = action?.payload?.callerID;
      state.locationError = null;
      if (callerID) {
        state.callQueue[callerID] = {
          ...state.callQueue[callerID],
          isManualSearch: true,
        };
      }
    },
    ['CLEAR_LOCATION_ERROR']: state => {
      state.locationError = null;
    },
    ['FETCH_RAD_SESSION_SUCCESS']: (state, action) => {
      const { id, payload } = action;
      state.callQueue = handleEmergencySessionData(state.callQueue, {
        id,
        payload,
      });
    },
    ['FETCH_RAD_SESSION_FAILED']: state => {
      // Do nothing. Can be used to display a failed to fetch additional data
      // message if requirements call for it
      return state;
    },
    ['incidents/setSelectedAlertID']: (state, action) => {
      if (action.payload !== null) {
        state.selectedCallerID = null;
      }
    },
    ['incidents/viewAllAlerts']: state => {
      // When viewing an alert and a new call comes in, `numberWithNewLocation`,
      // is set in `ADD_LOCATION_TO_QUEUE` but there is no handling for drawing
      // its marker, so the value doesn't get reset to null. Explicitly set it
      // to null when closing the event pane.
      state.numberWithNewLocation = null;
    },
    ['APP_INIT_START']: state => {
      const migrationList = [MW_5_10_0];
      let newState = { ...state };
      migrationList.forEach(migration => {
        newState = runMigration(newState, 'emergencyCalls', migration);
      });
      return newState;
    },
  },
});

export const {
  addLocation,
  removeAllCalls,
  removeCall,
  resetNumberWithNewLocation,
  setRgAddress,
  setSelectedCallerID,
  viewAllCalls,
  inactiveCalls,
  expiredCalls,
  setAgencyShareChatToastReceived,
  setCallReceived,
  setCallShared,
  setSharedWithAgencies,
  setCallRead,
  setCallUnread,
  resetUnreadCalls,
  getNumNotifications,
  setCallChatParticipants,
  setCallExpired,
  setTT911Sounds,
  setAdrQueryStatus,
  setAdrQueryData,
} = emergencyCallsSlice.actions;

export const callQueue = state => state.emergencyCalls.callQueue;

// Note: Use of blacklist in both store.js and here results in all keys except
// the blacklisted key here being persisted
const persistConfig = {
  key: 'emergencyCalls',
  storage,
  blacklist: [
    'isJVHermesInitialized',
    'locationError',
    'numberWithNewLocation',
  ],
};

const persistedReducer = persistReducer(
  persistConfig,
  emergencyCallsSlice.reducer,
);

export default persistedReducer;
