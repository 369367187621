import React, { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { withTheme } from 'styled-components';
import Box from '@rsos/base-components/Box';
import Portal from '@rsos/base-components/Portal';
import {
  BACKDROP_STYLE,
  CONTAINER_STYLE,
  BODY_WRAPPER_STYLE,
} from './Modal.styles';
import {
  ModalDialog,
  ModalDialogInner,
} from './ModalComponents/ModalDialog.styles';

function Modal({
  isOpened,
  transparent,
  onClose,
  portalPriority,
  disableBackdropClick,
  children,
  noBorderRadius,
  width,
  padding,
  id,
  theme,
}) {
  const [isInDOM, setIsInDOM] = useState(false);
  useEffect(() => {
    if (!isInDOM && isOpened) {
      setIsInDOM(true);
    }
  }, [isOpened, isInDOM]);

  const handleClickOnBackdrop = () => {
    if (!disableBackdropClick && onClose) {
      onClose();
    }
  };

  const stopPropagation = e => e.stopPropagation();

  const onDisappear = () => {
    setIsInDOM(false);
  };
  if (!isInDOM) return null;

  const modal = (
    <AnimatedModal
      isOpened={isOpened}
      onDisappear={onDisappear}
      onBackdropClick={handleClickOnBackdrop}
      theme={theme}
    >
      <ModalDialog width={width}>
        <ModalDialogInner
          onClick={stopPropagation}
          transparent={transparent}
          noBorderRadius={noBorderRadius}
          padding={padding}
          theme={theme}
        >
          <Box grow overflowY="auto">
            {children}
          </Box>
        </ModalDialogInner>
      </ModalDialog>
    </AnimatedModal>
  );

  return (
    <Portal id={id} priority={portalPriority}>
      {modal}
    </Portal>
  );
}

const AnimatedModal = ({
  isOpened,
  onDisappear,
  onBackdropClick,
  children,
  theme,
}) => {
  const [isFirstRender, setFirstRender] = useState(true);

  const isVisible = isOpened && !isFirstRender;

  useEffect(() => {
    setFirstRender(false);
  }, []);

  const backdropAnimation = useSpring({
    opacity: isVisible ? 0.3 : 0,
    config: {
      tension: 350,
    },
  });

  const backdropStyle = {
    ...BACKDROP_STYLE,
    ...{ background: theme.colors.modal.scrim },
    ...backdropAnimation,
  };
  const containerStyle = {
    ...CONTAINER_STYLE,
    pointerEvents: isVisible ? 'auto' : 'none',
  };

  const bodyWrapperAnimation = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: `scale(${isVisible ? 1 : 0.96})`,
    config: {
      tension: 450,
      friction: 35,
    },
    onRest: () => {
      if (!isVisible) onDisappear();
    },
  });

  const bodyWrapperStyle = {
    ...BODY_WRAPPER_STYLE,
    ...bodyWrapperAnimation,
  };

  return (
    <>
      <animated.div style={backdropStyle} />
      <div style={containerStyle} onClick={onBackdropClick}>
        <animated.div style={bodyWrapperStyle}>{children}</animated.div>
      </div>
    </>
  );
};

export default withTheme(Modal);
