import React from 'react';
import { AudioControlIcon, AudioMuteIcon } from '@rsos/assets/icons';
import { Box, Button, Dropdown } from '@rsos/components/capstone/base';
import {
  DropdownDialog,
  DropdownBody,
} from '@rsos/components/capstone/base/Dropdown/DropdownComponents';
import ThemeControl from '../../../../common/ThemeControl';
import CallTakerMode from '../../CallTakerMode';
import SoundPlayer from '../SoundPlayer';
import AudioDisabled from './AudioDisabled';

const SettingsNonResponsive = ({
  path,
  userAudioSettings,
  orgAudioSettings,
  setIsCTMClicked,
  isCTMEnabled,
}) => {
  const isSoundOn = Object.values(orgAudioSettings).some(o => o?.sound_on);
  const isVolumeOn = Object.keys(userAudioSettings).some(
    o => userAudioSettings[o].volume > 0 && orgAudioSettings[o]?.sound_on,
  );

  return (
    <Box horizontal>
      {isCTMEnabled && <CallTakerMode setIsCTMClicked={setIsCTMClicked} />}
      {isSoundOn ? (
        <Box data-name="navigation-audio" position="relative">
          <Dropdown
            triggerElement={
              isVolumeOn
                ? AudioControlButtonVolumeOn
                : AudioControlButtonVolumeOff
            }
          >
            <DropdownDialog top={43} right={2} width="278px">
              <DropdownBody>
                <SoundPlayer orgAudioSettings={orgAudioSettings} />
              </DropdownBody>
            </DropdownDialog>
          </Dropdown>
        </Box>
      ) : (
        <AudioDisabled />
      )}
      <ThemeControl path={path} />
    </Box>
  );
};

export default SettingsNonResponsive;

const AudioControlButtonVolumeOn = ({ selected, onClick }) => {
  return (
    <Button
      btnType="tertiary"
      size="small"
      dataName="audio-control-sound-button"
      onClick={onClick}
      selected={selected}
    >
      <AudioControlIcon color="secondaryText" />
    </Button>
  );
};
const AudioControlButtonVolumeOff = ({ selected, onClick }) => {
  return (
    <Button
      btnType="tertiary"
      size="small"
      dataName="audio-control-sound-button"
      onClick={onClick}
      selected={selected}
    >
      <AudioMuteIcon color="secondaryText" />
    </Button>
  );
};
