import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import useCallTakerModePerms from '@rsos/utils/useCallTakerModePerms';
import { ErrorModal, InitiationModal, PositionIDSelectionModal } from '.';

const arePropsEqual = (prevProps, nextProps) => {
  return (
    prevProps.isCTMClicked === nextProps.isCTMClicked && !nextProps.isCTMClicked
  );
};
const CallTakerModalsContainer = memo(({ isCTMClicked, setIsCTMClicked }) => {
  const [isInitiationModalOpen, setIsInitiationModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [
    isPositionIDSelectionModalOpen,
    setIsPositionIDSelectionModalOpen,
  ] = useState(false);

  const ctmPermission = useCallTakerModePerms();

  const { workstation_positions = [] } = useSelector(
    state => state.psaps.currentPSAP,
  );

  const { user } = useSelector(state => state.sinatra);
  const applicationName = user.currentRole.application;
  const call_taker_mode_settings =
    user?.profile?.attributes?.[applicationName]?.call_taker_mode_settings;

  const toggleInitiationModal = () => {
    setIsInitiationModalOpen(prev => !prev);
    if (isCTMClicked) {
      setIsCTMClicked(false);
    }
  };
  const toggleErrorModal = () => {
    setIsErrorModalOpen(prev => !prev);
    if (isCTMClicked) {
      setIsCTMClicked(false);
    }
  };
  const togglePositionIDSelectionModal = () => {
    if (isCTMClicked) {
      setIsCTMClicked(false);
      setIsPositionIDSelectionModalOpen(false);
    } else {
      setIsPositionIDSelectionModalOpen(true);
    }
  };

  const handleTurnOnClick = () => {
    if (workstation_positions.length) {
      togglePositionIDSelectionModal();
    } else {
      setIsErrorModalOpen(true);
    }
  };
  useEffect(() => {
    if (!call_taker_mode_settings && ctmPermission) {
      setIsInitiationModalOpen(true);
    }
  }, [ctmPermission, call_taker_mode_settings]);

  useEffect(() => {
    if (isCTMClicked) {
      const shouldOpenModal =
        !isPositionIDSelectionModalOpen &&
        call_taker_mode_settings &&
        !call_taker_mode_settings.is_enabled;

      if (shouldOpenModal) {
        workstation_positions.length
          ? setIsPositionIDSelectionModalOpen(true)
          : setIsErrorModalOpen(true);
      } else {
        setIsPositionIDSelectionModalOpen(false);
        setIsErrorModalOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call_taker_mode_settings, isCTMClicked]);

  return (
    <>
      {isInitiationModalOpen && (
        <InitiationModal
          closeIntiationModal={toggleInitiationModal}
          isOpened={isInitiationModalOpen}
          positionIDs={workstation_positions}
          handleTurnOnClick={handleTurnOnClick}
        />
      )}

      {isPositionIDSelectionModalOpen && (
        <PositionIDSelectionModal
          togglePositionIDSelectionModal={togglePositionIDSelectionModal}
          isOpened={isPositionIDSelectionModalOpen}
          positionIDs={workstation_positions}
        />
      )}

      {isErrorModalOpen && (
        <ErrorModal
          isOpened={isErrorModalOpen}
          toggleErrorModal={toggleErrorModal}
        />
      )}
    </>
  );
}, arePropsEqual);
export default CallTakerModalsContainer;
