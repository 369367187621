import { useSelector } from 'react-redux';
import {
  RAPIDSOS_PREMIUM,
  ESRI_MAP,
  CALL_TAKER_MODE,
  ALI_GEOCOMM,
} from '@rsos/capstone/src/constants/capabilities';
import {
  PREMIUM_ALI_GEOCOMM,
  PREMIUM_ESRI_MAP,
} from '@rsos/constants/permissions';

export default function useCallTakerModePerms() {
  return useSelector(state => {
    return !!(
      state.psaps.currentPSAP &&
      state.psaps.currentPSAP.activeCapabilities &&
      state.psaps.currentPSAP.activeCapabilities[RAPIDSOS_PREMIUM] &&
      state.psaps.currentPSAP.activeCapabilities[ESRI_MAP] &&
      state.sinatra?.user?.currentRole?.permissions?.includes(
        PREMIUM_ESRI_MAP,
      ) &&
      state.psaps.currentPSAP.activeCapabilities[CALL_TAKER_MODE] &&
      state.psaps.currentPSAP.activeCapabilities[ALI_GEOCOMM] &&
      state.sinatra?.user?.currentRole?.permissions?.includes(
        PREMIUM_ALI_GEOCOMM,
      )
    );
  });
}
