import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.min.css';
import ThemeProvider from '@rsos/theme';
import { readMetaTag } from '@rsos/utils/metaTags';
import { AppPathsProvider } from './AppPathsContext';
import LayoutContent from './LayoutContent';

const DEFAULT_THEME = 'light';
const ICSP_THEME = 'icsp';
const feAppName = readMetaTag('fe-app-name');

const fetchTheme = (app, themeRedux) => {
  const themeByApp = {
    authentication: DEFAULT_THEME,
    incident_central_station: ICSP_THEME,
  };
  return themeByApp[app] || themeRedux;
};

const Layout = ({ children, appPaths, persistor }) => {
  const [themeName, setThemeName] = useState('DEFAULT_THEME');

  const themeRedux = useSelector(state => state.sinatra.ui.theme);

  useEffect(() => {
    setThemeName(fetchTheme(feAppName, themeRedux));
  }, [themeRedux]);

  return (
    <AppPathsProvider value={appPaths}>
        <ThemeProvider themeName={themeName}>
          <LayoutContent children={children} persistor={persistor} />
        </ThemeProvider>
    </AppPathsProvider>
  );
};

export default Layout;
