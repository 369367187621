import React, { useState, useEffect } from 'react';
import { useSpring } from 'react-spring';
import { withTheme } from 'styled-components';
import { Box } from '@rsos/components/capstone/base';
import { EMERGENCY_DATA } from '@rsos/constants/appNames';
import { readMetaTag } from '@rsos/utils/metaTags';
import { RapidSOSLogoCompact, FullLogo } from '../../common/logo/RapidSOSLogo';
import { PremiumText, TextContainer } from './PremiumLogo.styles';
import { ANIMATION_DURATION, ANIMATION_DELAY } from './constants';

const feAppName = readMetaTag('fe-app-name');

const PremiumLogo = ({ initialized, theme, compact }) => {
  const [isLogoAnimatedSeen, setIsLogoAnimatedSeen] = useState(
    localStorage.getItem('isLogoAnimatedSeen'),
  );
  const [displayAnimation, setDisplayAnimation] = useState(false);

  const { name: themeName = '' } = theme.colors;

  useEffect(() => {
    if (initialized) {
      setTimeout(() => {
        setDisplayAnimation(true);
      }, ANIMATION_DELAY);
    }
  }, [initialized]);

  useEffect(() => {
    if (displayAnimation && !isLogoAnimatedSeen) {
      localStorage.setItem('isLogoAnimatedSeen', true);
      setTimeout(() => {
        setIsLogoAnimatedSeen(true);
      }, ANIMATION_DURATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayAnimation]);

  const { ...springProps } = useSpring({
    to: displayAnimation
      ? { width: '73px' }
      : {
          width: '0px',
        },
    from: {
      width: '0px',
    },
    config: {
      dur: 500,
    },
  });
  const isStatic = feAppName !== EMERGENCY_DATA || isLogoAnimatedSeen;
  return compact ? (
    <RapidSOSLogoCompact
      isLogoAnimatedSeen={isLogoAnimatedSeen}
      displayAnimation={displayAnimation}
      static={isStatic}
    />
  ) : (
    <Box horizontal flow={7} align="center">
      <FullLogo
        isLogoAnimatedSeen={isLogoAnimatedSeen}
        displayAnimation={displayAnimation}
        static={isStatic}
        color={themeName == 'dark' ? 'background' : 'primaryText'}
      />
      <TextContainer style={isStatic ? null : springProps}>
        <PremiumText
          color={themeName === 'dark' ? 'background' : 'primaryText'}
        />
      </TextContainer>
    </Box>
  );
};
export default withTheme(PremiumLogo);
