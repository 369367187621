import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { ThemeControlIcon } from '@rsos/assets/icons';
import { Box, Button } from '@rsos/components/capstone/base';
import {
  BASEMAP_ESRI_NAVIGATION_DARK,
  BASEMAP_ESRI_NAVIGATION_LIGHT,
  BASEMAP_ESRI_SATELLITE,
  BASEMAP_GOOGLE_HYBRID,
  BASEMAP_GOOGLE_ROADMAP,
} from '@rsos/constants/basemapAddonNames';
import { ESRI, GOOGLE } from '@rsos/maps/constants/mapProviders';
import { selectTheme, setBasemap } from '@rsos/sinatra';
import useIsIRP from '@rsos/utils/useIsIRP';
import { trackThemeControlClicks } from './themeControlTrackings';

export const IconWrapper = styled(Box).attrs({
  color: 'secondaryText',
})``;

const ThemeControl = ({ path, theme }) => {
  const dispatch = useDispatch();

  const { name: themeName = '' } = theme.colors;

  const { irpVersion } = useIsIRP();

  const mapProvider = useSelector(state => state.sinatra.ui.mapProvider);

  const basemap = useSelector(state => state.sinatra.ui.basemap);

  const isSatelliteBasemap = useMemo(() => {
    return (
      basemap === BASEMAP_ESRI_SATELLITE || basemap === BASEMAP_GOOGLE_HYBRID
    );
  }, [basemap]);

  const [themeLocal, setThemeLocal] = useState(themeName);

  const handleThemeSwitch = useCallback(() => {
    const themeToSwitchTo = themeLocal === 'light' ? 'dark' : 'light';
    let basemapToSwitchTo = '';

    setThemeLocal(themeToSwitchTo);
    trackThemeControlClicks(themeToSwitchTo, irpVersion);
    dispatch(selectTheme(themeToSwitchTo));

    if (!isSatelliteBasemap) {
      if (mapProvider === ESRI) {
        basemapToSwitchTo =
          themeToSwitchTo === 'light'
            ? BASEMAP_ESRI_NAVIGATION_LIGHT
            : BASEMAP_ESRI_NAVIGATION_DARK;
      } else if (mapProvider === GOOGLE) {
        basemapToSwitchTo = BASEMAP_GOOGLE_ROADMAP;
      }

      dispatch(setBasemap(basemapToSwitchTo));
    }
  }, [dispatch, irpVersion, isSatelliteBasemap, mapProvider, themeLocal]);

  const isThemeablePage =
    path === '/home' ||
    path === '/irp-home' ||
    path === '/query-interface' ||
    path === '/irp-query-interface' ||
    path === '/central-station' ||
    path.includes('/admin');

  return (
    <Box data-name="navigation-theme">
      {isThemeablePage && (
        <Button
          btnType="tertiary"
          size="small"
          onClick={handleThemeSwitch}
          data-name={`theme-control-turn-${
            theme === 'light' ? 'on' : 'off'
          }-night-mode`}
        >
          <IconWrapper>
            <ThemeControlIcon
              viewboxWidth={24}
              viewboxHeight={24}
              width={20}
              height={16}
            />
          </IconWrapper>
        </Button>
      )}
    </Box>
  );
};

export default withTheme(ThemeControl);
