import {
  ACTIVE_ASSAILANT,
  TRAIN_DERAILMENT,
  TEST_TRAIN_DERAILMENT,
} from '@rsos/constants/alertTypes';
import findData from '@rsos/ellington/src/helpers/findData';

const CATEGORY = 'category';
const META = 'meta';
export const ALERTDATAEVENT = 'alertdataevent';

/**
 * Util function to parse out nested data based on a key name. Currently used
 * for `Suggested Script Responses`, `livestreams`, `Chatbot Responses` and
 * `feedbackForm`. This function assumes that there aren't duplicate nested keys
 * in the json path, otherwise this function will return data in the incorrect
 * format.
 * @param {Object} allData - adr data from the RAD Session endpoint
 * @param {String} keyName - key name to parse the data
 * @returns {Object} returns parsed found data, otherwise return an empty object
 */
export const parseNestedData = (allData, keyName) => {
  let parsedData = {};
  const parseData = data => {
    if (
      typeof keyName !== 'string' ||
      typeof data !== 'object' ||
      Array.isArray(data) ||
      data === null
    ) {
      return;
    }

    const keys = Object.keys(data);
    keys.forEach(key => {
      // Skip parsing if it's in the metadata, the actual data doesn't live there
      if (key !== META) {
        if (key === keyName) {
          parsedData = data;
        } else {
          parseData(data[key]);
        }
      }
    });
  };
  parseData(allData);
  return parsedData;
};

/**
 * Util function to parse out data that contain display schemas. This should be
 * used in conjunction with filterNonAdrData to filter data with the keys
 * `Suggested Script Responses`, `livestreams`, `Chatbot Responses`, and
 * `feedbackForm`
 * @param {Object} allData - adr data from the RAD Session endpoint
 */
export const parseDataWithDisplaySchema = allData => {
  if (
    typeof allData !== 'object' ||
    allData === null ||
    Array.isArray(allData)
  ) {
    return [];
  }

  const paths = parseJsonPath({
    data: allData,
    path: [],
    matchingKey: 'display_schema',
    jsonPaths: [],
  });

  // Take the second last item in the json path and find its corresponding data
  const flattenedPaths = paths.map(path => {
    return path.slice(-2, -1); // second last item
  });

  const org = paths?.[0]?.[0];

  const parsedData = flattenedPaths.map(path => {
    const allJsonPaths = parseJsonPath({
      data: allData,
      path: [],
      matchingKey: path[0],
      jsonPaths: [],
    });
    const data = allJsonPaths.reduce((acc, key) => {
      const foundData = findData(allData, key);
      if (foundData?.display_schema) {
        acc = { ...acc, ...foundData, orgName: org };
      } else {
        // Assume this is the body
        acc = {
          ...acc,
          body: {
            ...foundData,
          },
        };
      }
      return acc;
    }, {});
    return data;
  });
  return parsedData;
};

/**
 * Helper function to recursively get the json paths leading to the `matchingKey`
 * @param {Object} data - data
 * @param {Array} path - current json path of the data
 * @param {String} matchingKey - the identifying key name to match against
 * @returns {Array} jsonPaths - a list of lists. e.g. [ ['this', 'json', 'path'] ]
 */
const parseJsonPath = ({ data, path = [], matchingKey, jsonPaths = [] }) => {
  if (typeof data !== 'object' || Array.isArray(data) || data === null) {
    return jsonPaths;
  }
  for (const key in data) {
    if (key === matchingKey) {
      jsonPaths.push([...path, key]);
      return [...path, key];
    } else {
      parseJsonPath({
        data: data[key],
        path: [...path, key],
        matchingKey,
        jsonPaths,
      });
    }
  }
  // returns a list of lists if a match is found
  return jsonPaths;
};

/**
 * Helper function to filter the data based on the top level key of a json path
 * @param {Object} data - adr data
 * @param {Array} uniqueTopLevelKeys - list of top level key names
 * @returns {Object} returns a subset of `data`
 */
const filterData = (data, uniqueTopLevelKeys) => {
  if (uniqueTopLevelKeys.length) {
    const filteredData = Object.keys(data)
      .filter(key => {
        return !uniqueTopLevelKeys.includes(key);
      })
      .reduce((acc, key) => {
        acc[key] = data[key];
        return acc;
      }, {});
    return filteredData;
  }
  // returns the original data if there are no matches to the keyOrKeys
  return data;
};

/**
 * Helper function to get the top level keys of a json path
 * @param {Array} paths - a list of lists. e.g. [ ['this', 'json', 'path'] ]
 * @returns {Array} - returns an array with unique string values e.g. ['unique', 'string', 'values']. returns an empty array if `paths` is empty
 */
const getUniqueTopLevelKeys = paths => {
  const topLevelKeys = paths.map(path => path[0]);
  const flattenedPaths = topLevelKeys.flat();
  const uniqueTopLevelKeys = flattenedPaths.filter((key, pos) => {
    return flattenedPaths.indexOf(key) === pos;
  });
  return uniqueTopLevelKeys;
};

/**
 * Util function to filter out data that doesn't contain display schemas based
 * on a key name or list of key names.
 * This should be used in conjunction with parseDataWithDisplaySchema the adr
 * by these keys `Suggested Script Responses`, `livestreams`, and
 * `Chatbot Responses` and `feedbackForm`
 * @param {Object} allData - adr data from the RAD Session endpoint
 * @param {String|Array} - keyOrKeys - the key name(s) to filter by
 */
export const filterDataByKeys = (allData, keyOrKeys = []) => {
  // return allData if keyOrKeys is an array, but is empty or doesn't contain
  // only string values
  if (Array.isArray(keyOrKeys)) {
    if (!keyOrKeys.length) {
      return allData;
    }
    for (const key in keyOrKeys) {
      if (typeof keyOrKeys[key] !== 'string' || !keyOrKeys[key]) {
        return allData;
      }
    }
  } else if (typeof keyOrKeys !== 'string') {
    return allData;
  }

  if (typeof keyOrKeys === 'string') {
    const paths = parseJsonPath({
      data: allData,
      path: [],
      matchingKey: keyOrKeys,
      jsonPaths: [],
    });
    const uniqueTopLevelKeys = getUniqueTopLevelKeys(paths);

    return filterData(allData, uniqueTopLevelKeys);
  } else if (Array.isArray(keyOrKeys)) {
    const uniqueTopLevelKeys = keyOrKeys
      .map(key => {
        const paths = parseJsonPath({
          data: allData,
          path: [],
          matchingKey: key,
          jsonPaths: [],
        });
        const uniqueKeys = getUniqueTopLevelKeys(paths);
        return uniqueKeys;
      })
      .flat();

    return filterData(allData, uniqueTopLevelKeys);
  }
};

/**
 * Util function to filter out data based on a category or list of categories
 * This should be used in conjunction with parseDataWithDisplaySchema and
 * optionally with filterDataByKeys.
 * @param {Object} allData - adr data from the RAD Session endpoint
 * @param {String|Array} - categoryOrCategories - the category name(s) to filter
 * by
 */
export const filterDataByCategories = (allData, categoryOrCategories = []) => {
  // return allData if categoryOrCategories is an array, but is empty or doesn't contain
  // only string values
  if (Array.isArray(categoryOrCategories)) {
    if (!categoryOrCategories.length) {
      return allData;
    }
    for (const category in categoryOrCategories) {
      if (
        typeof categoryOrCategories[category] !== 'string' ||
        !categoryOrCategories[category]
      ) {
        return allData;
      }
    }
  } else if (typeof categoryOrCategories !== 'string') {
    return allData;
  }

  // Helper function
  const getTopLevelKeys = category => {
    // get the json paths for the data that contains category: 'alertdataevent'
    const paths = parseJsonPath({
      data: allData,
      path: [],
      matchingKey: CATEGORY,
      jsonPaths: [],
    })
      .filter(path => {
        // Check if the path contains `meta` to be sure this is the metadata and
        // not the data itself
        return path.includes(META);
      })
      .filter(path => {
        // Check the value of the `category`
        const foundData = findData(allData, path);
        return foundData === category;
      });

    return getUniqueTopLevelKeys(paths);
  };

  if (typeof categoryOrCategories === 'string') {
    const uniqueTopLevelKeys = getTopLevelKeys(categoryOrCategories);
    return filterData(allData, uniqueTopLevelKeys);
  } else if (Array.isArray(categoryOrCategories)) {
    const uniqueTopLevelKeys = categoryOrCategories
      .map(category => {
        return getTopLevelKeys(category);
      })
      .flat();
    return filterData(allData, uniqueTopLevelKeys);
  }
};

/**
 * Util function to parse all data with based on a category value. This data
 * does not get rendered in the data card, so shouldn't contain display schemas
 * @param {Object} allData - adr data from the RAD Session endpoint
 * @param {String|Array} - categoryOrCategories - the category name(s) to parse
 * by
 */
export const parseDataByCategories = (allData, categoryOrCategories = []) => {
  // return allData if keyOrKeys is an array, but is empty or doesn't contain
  // only string values
  if (Array.isArray(categoryOrCategories)) {
    if (!categoryOrCategories.length) {
      return allData;
    }
    for (const category in categoryOrCategories) {
      if (
        typeof categoryOrCategories[category] !== 'string' ||
        !categoryOrCategories[category]
      ) {
        return allData;
      }
    }
  } else if (typeof categoryOrCategories !== 'string') {
    return allData;
  }

  // Helper function
  const getTopLevelKeys = category => {
    const paths = parseJsonPath({
      data: allData,
      path: [],
      matchingKey: CATEGORY,
      jsonPaths: [],
    })
      .filter(path => {
        // Check if the path contains `meta` to be sure this is the metadata and
        // not the data itself
        return path.includes(META);
      })
      .filter(path => {
        // Check the value of the `category`
        const foundData = findData(allData, path);
        return foundData !== category;
      });

    return getUniqueTopLevelKeys(paths);
  };

  if (typeof categoryOrCategories === 'string') {
    const uniqueTopLevelKeys = getTopLevelKeys(categoryOrCategories);
    return filterData(allData, uniqueTopLevelKeys);
  } else if (Array.isArray(categoryOrCategories)) {
    const uniqueTopLevelKeys = categoryOrCategories
      .map(category => {
        return getTopLevelKeys(category);
      })
      .flat();
    return filterData(allData, uniqueTopLevelKeys);
  }
};

/**
 * Util function to check if an alert is regular or if it belongs to AAA or Train Incident alerts by its emergency type
 * @param {Object} emergencyType - emergency type of an alert
 */

export function isRegularAlert(emergencyType) {
  return (
    emergencyType &&
    emergencyType.name !== ACTIVE_ASSAILANT &&
    emergencyType.name !== TRAIN_DERAILMENT &&
    emergencyType.name !== TEST_TRAIN_DERAILMENT
  );
}

/**
 * Util function to check if an alert is a test alert
 * @param {Object} emergencyType - emergency type of an alert
 */

export function isTestAlert(emergencyType) {
  return emergencyType && emergencyType.name.includes('TEST');
}
/**
 * Util function to resolve the header text for the event pane
 * @param {Object} emergencyType - emergency type of an alert
 * @param {Object} siteType - site type of an alert
 */

export const getAlertHeaderText = (emergencyType, siteType) =>
  isTestAlert(emergencyType)
    ? 'TEST: ' + emergencyType?.display_name?.replace('Test', '')
    : isRegularAlert(emergencyType)
    ? siteType + ' ' + emergencyType?.display_name
    : emergencyType?.display_name;
