import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { ACTIVE_ASSAILANT, TRAIN_DERAILMENT } from '@rsos/constants/alertTypes';

/**
 * Function that returns an object containing alerts that have not been removed
 * from the queue to prevent removed alerts from being rendered on page refresh
 * @param {Object} incidents - incidents in incidentsSlice
 * @param {Array} removedAlerts - list of removed alert IDs
 */
const filterAlerts = (incidents, removedAlerts) => {
  return Object.keys(incidents)
    .filter(alertID => {
      return incidents[alertID] && !removedAlerts.includes(alertID);
    })
    .reduce((res, key) => ((res[key] = incidents[key]), res), {});
};

const sortAlerts = (alerts, alertsQueueOrder, isCentralStation) => {
  if (isCentralStation) {
    return Object.values(alerts).sort((a, b) => {
      const unreadAlertIdxA = alertsQueueOrder.findIndex(
        alert => alert.alertID === a.alert_id,
      );
      const unreadAlertIdxB = alertsQueueOrder.findIndex(
        alert => alert.alertID === b.alert_id,
      );
      if (unreadAlertIdxA !== -1 && unreadAlertIdxB !== -1) {
        return (
          alertsQueueOrder[unreadAlertIdxB].latestMsgTime -
          alertsQueueOrder[unreadAlertIdxA].latestMsgTime
        );
      }
      if (unreadAlertIdxA !== -1) return -1;
      if (unreadAlertIdxB !== -1) return 1;
      return b.incident_time - a.incident_time;
    });
  } else {
    return Object.values(alerts).sort(
      (a, b) => b.incident_time - a.incident_time,
    );
  }
};

/**
 * Filters the alerts
 * @param {Object} alerts - state.incidents
 */
export const filteredAlertsSelector = createSelector(
  incidents => incidents,
  incidents => {
    return filterAlerts(incidents.alerts, incidents.removedAlerts);
  },
);

/**
 * Function that returns an object containing alerts that that are not Active Assaialnt Alerts
 * and have not been removed from the queue
 * @param {Object} incidents - incidents in incidentsSlice
 * @param {Array} removedAlerts - list of removed alert IDs
 */

const filterRegularAlerts = (incidents, removedAlerts) => {
  return Object.keys(incidents)
    .filter(alertID => {
      return (
        incidents[alertID] &&
        !removedAlerts.includes(alertID) &&
        incidents[alertID].emergency_type.name !== ACTIVE_ASSAILANT &&
        incidents[alertID].emergency_type.name !== TRAIN_DERAILMENT &&
        !incidents[alertID].emergency_type.name.startsWith('TEST')
      );
    })
    .reduce((res, key) => ((res[key] = incidents[key]), res), {});
};

/**
 * selector to return not Active Assailant alerts
 * @param {Object} alerts - state.incidents
 */
export const filteredRegularAlertsSelector = createSelector(
  incidents => incidents,
  incidents => {
    return filterRegularAlerts(incidents.alerts, incidents.removedAlerts);
  },
);

/**
 * Function that returns an object containing Active Assailant alerts only and
 * that have not been removed from the queue
 * @param {Object} incidents - incidents in incidentsSlice
 * @param {Array} removedAlerts - list of removed alert IDs
 */

const filterTrainAlerts = (incidents, removedAlerts) => {
  return Object.keys(incidents)
    .filter(alertID => {
      return (
        incidents[alertID] &&
        !removedAlerts.includes(alertID) &&
        incidents[alertID].emergency_type.name === TRAIN_DERAILMENT
      );
    })
    .reduce((res, key) => ((res[key] = incidents[key]), res), {});
};

/**
 * Function that returns an object containing Test alerts only and
 * that have not been removed from the queue
 * @param {Object} incidents - incidents in incidentsSlice
 * @param {Array} removedAlerts - list of removed alert IDs
 */

const filterTestAlerts = (incidents, removedAlerts) => {
  return Object.keys(incidents)
    .filter(alertID => {
      return (
        incidents[alertID] &&
        !removedAlerts.includes(alertID) &&
        incidents[alertID].emergency_type.name.startsWith('TEST')
      );
    })
    .reduce((res, key) => ((res[key] = incidents[key]), res), {});
};

/**
 * Function that returns an object containing Active Assailant alerts only and
 * that have not been removed from the queue
 * @param {Object} incidents - incidents in incidentsSlice
 * @param {Array} removedAlerts - list of removed alert IDs
 */

const filterAAAlerts = (incidents, removedAlerts) => {
  return Object.keys(incidents)
    .filter(alertID => {
      return (
        incidents[alertID] &&
        !removedAlerts.includes(alertID) &&
        incidents[alertID].emergency_type.name === ACTIVE_ASSAILANT
      );
    })
    .reduce((res, key) => ((res[key] = incidents[key]), res), {});
};

/**
 * selector to return Train Incident alerts
 * @param {Object} alerts - state.incidents
 */
export const filteredAAAlertsSelector = createSelector(
  incidents => incidents,
  incidents => {
    return filterAAAlerts(incidents.alerts, incidents.removedAlerts);
  },
);

export const filteredTrainAlertsSelector = createSelector(
  incidents => incidents,
  incidents => {
    return filterTrainAlerts(incidents.alerts, incidents.removedAlerts);
  },
);

/**
 * selector to return Test alerts
 * @param {Object} alerts - state.incidents
 */
export const filteredTestAlertsSelector = createSelector(
  incidents => incidents,
  incidents => {
    return filterTestAlerts(incidents.alerts, incidents.removedAlerts);
  },
);
/**
 * Sorts the alerts
 * @param {Object} incidents - {alerts, alertsQueueOrder, isCentralStation}
 */

export const sortedAlertsSelector = createSelector(
  incidents => incidents,
  ({ alerts, alertsQueueOrder, isCentralStation }) => {
    return sortAlerts(alerts, alertsQueueOrder, isCentralStation);
  },
);

const filterActiveToastAlerts = (alerts, activeAlertToasts) => {
  let subsetAlertsFromToasts = {};
  if (activeAlertToasts.length > 0) {
    activeAlertToasts.forEach(toast => {
      const alertID = toast.alertID;
      if (alerts[alertID]) {
        subsetAlertsFromToasts = {
          ...subsetAlertsFromToasts,
          [alertID]: alerts[alertID],
        };
      }
    });
  }
  return subsetAlertsFromToasts;
};

/**
 * selector to return a subset of alerts based on active alert toasts
 * @param {Object} incidents - state.incidents
 * @param {Array} activeAlertToasts - state.incidents
 */

export const activeToastAlertsSelector = createSelector(
  incidents => incidents,
  incidents => {
    return filterActiveToastAlerts(
      incidents.alerts,
      incidents.activeAlertToasts,
    );
  },
);
// The code below is ported from `DataCardContainer`. importing the hooks from `ICSPNavigation` causes build failures due to `emergency_data` leaking through
class DataCardDependencyError extends Error {
  constructor(...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DataCardDependencyError);
    }
    this.name = 'DataCardDependencyError';
  }
  toString() {
    return `${this.name}: ${this.message}`;
  }
}

export const useSelectedAlert = () => {
  const { alerts, selectedAlertID } = useSelector(state => {
    const { incidents } = state;
    if (!incidents) {
      throw new DataCardDependencyError(
        'The incidents slice is missing in redux state.',
      );
    }
    return incidents;
  });
  const [currentAlert, setCurrentAlert] = useState(alerts?.[selectedAlertID]);
  useEffect(() => {
    setCurrentAlert(alerts[selectedAlertID]);
  }, [alerts, selectedAlertID]);
  return currentAlert || {};
};

export const useCurrentAdrData = currentAlert => {
  const { data: adrData = {} } = useSelector(state => {
    const { adrData } = state;
    if (!adrData) {
      throw new DataCardDependencyError(
        'The adrData slice is missing in redux state.',
      );
    }
    return adrData;
  });
  const [currentAdrData, setCurrentAdrData] = useState(null);
  useEffect(() => {
    if (!currentAlert) {
      setCurrentAdrData(null);
      return;
    }
    const alertId = currentAlert.alert_id;
    setCurrentAdrData(adrData[`alert://${alertId}`]);
  }, [currentAlert, adrData]);
  return currentAdrData;
};
