import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxHeight: 16,
  viewboxWidth: 16,
};

const ChevronDownIcon = ({
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => {
  return (
    <SVGLoader
      viewboxHeight={viewboxHeight}
      viewboxWidth={viewboxWidth}
      {...rest}
    >
      <g>
        <path
          d="M8 13a1.559 1.559 0 0 1-1.153-.52L.307 5.047A1.233 1.233 0 1 1 2.153 3.42l5.72 6.5a.167.167 0 0 0 .254 0l5.72-6.5a1.233 1.233 0 1 1 1.846 1.627l-6.54 7.433A1.56 1.56 0 0 1 8 13Z"
          fill="currentColor"
        />
      </g>
    </SVGLoader>
  );
};

export default ChevronDownIcon;
