import ReactGA from 'react-ga';
import md5 from 'md5';

import { selectUserOrgByApp } from '@rsos/sinatra';
import { JURISDICTION_VIEW } from './constants/capabilities';

export const PRODUCTION_HOSTNAMES = [
  'rapidlite.rapidsos.com',
  'rapidsosportal.com',
];

export const GA_TRACKING_IDS = {
  prod: 'UA-50896176-11',
  nonProd: 'UA-50896176-15',
};

/**
 * Returns the corresponding GA tracking ID for the stack (non-prod or prod).
 */
export const determineGATrackingID = () => {
  const { hostname } = window.location;
  let gaKey = GA_TRACKING_IDS.nonProd;
  const found = PRODUCTION_HOSTNAMES.find(prod => hostname === prod);
  if (found || hostname.includes('prod')) {
    gaKey = GA_TRACKING_IDS.prod;
  }
  return gaKey;
};

/**
 * A class encapsulating Capstone specific GA tracking.
 */
class CapstoneGA {
  constructor() {
    this.store = null;
    this.trackingID = determineGATrackingID();
    ReactGA.initialize(this.trackingID);
  }

  checkStore() {
    // The following should only be shown during development.
    if (!this.store && process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(
        'The reference to the store was not set. First call initialize().',
      );
    }
  }

  /**
   * Returns an object with data points that can be used to define dimensions
   * in Google Analytics dashboards. Currently profile ID and the account ID.
   */
  getDimensionStats() {
    const { sinatra } = this.store.getState();
    const { psaps } = this.store.getState();
    const { id: profileID } = sinatra.user.profile;
    const accountID = psaps.currentPSAP.account_id;
    return {
      profileID,
      accountID,
    };
  }

  /**
   * Returns an MD5 hash of the current user's email.
   */
  getHashedEmail() {
    const { sinatra } = this.store.getState();
    const { email } = sinatra.user.profile;

    let hashedEmail;
    if (email) {
      hashedEmail = md5(email);
    }
    return hashedEmail;
  }

  /**
   * Initializes Google Analytics with the corresponding tracking key for the
   * current stack. Note that Google will automatically ignore localhost.
   * @param {Object} store - A reference to the redux store instance.
   * @param {Object} options - Options compatible with set() in the GA API
   */
  initialize(store, options = {}) {
    this.store = store;
    const appName = 'capstone';
    const appVersion = process.env.REACT_APP_FE_VERSION || '';
    ReactGA.set({
      appName,
      appId: `${appName}-${appVersion}`,
      appVersion,
      ...options,
    });
  }

  trackWithUser(action, category, nonInteraction = false) {
    try {
      this.checkStore();
      const hashed = this.getHashedEmail();
      const {
        profileID: dimension1,
        accountID: dimension2,
      } = this.getDimensionStats();

      ReactGA.set({
        dimension1,
        dimension2,
      });

      ReactGA.event({
        category: category || `${action}`,
        action: `${action}`,
        label: `${hashed}`,
        nonInteraction,
      });
      //eslint-disable-next-line no-empty
    } catch {}
  }

  trackWithoutUser(action, category, nonInteraction = false) {
    try {
      this.checkStore();
      const {
        profileID: dimension1,
        accountID: dimension2,
      } = this.getDimensionStats();

      ReactGA.set({
        dimension1,
        dimension2,
      });

      ReactGA.event({
        category: category || `User ${action}`,
        action: `User ${action}`,
        nonInteraction,
      });
      //eslint-disable-next-line no-empty
    } catch {}
  }

  trackEvent(action, category = '') {
    try {
      this.checkStore();
      const { sinatra, psaps = {} } = this.store.getState();
      const { currentPSAP = {} } = psaps;
      const { activeCapabilities = {} } = currentPSAP;
      const isJVEnabled = !!activeCapabilities[JURISDICTION_VIEW];
      const org = selectUserOrgByApp(sinatra, 'capstone');

      if (org && !!isJVEnabled) {
        this.trackWithUser(action, category);
      } else {
        this.trackWithoutUser(action, category);
      }
      //eslint-disable-next-line no-empty
    } catch {}
  }

  trackLogin() {
    try {
      this.checkStore();
      const { sinatra } = this.store.getState();
      const isAlreadyLoggedIn = sinatra.user.isLoggedIn;
      if (!isAlreadyLoggedIn) {
        this.trackEvent('login');
      }
      //eslint-disable-next-line no-empty
    } catch {}
  }

  trackView(page) {
    try {
      const {
        profileID: dimension1,
        accountID: dimension2,
      } = this.getDimensionStats();
      ReactGA.set({
        page,
        dimension1,
        dimension2,
      });

      ReactGA.pageview(page);
      //eslint-disable-next-line no-empty
    } catch {}
  }
}

/**
 * NOTE This returns a singleton of CapstoneGA
 */
export default new CapstoneGA();
