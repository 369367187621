import React from 'react';
import SVGLoader from './SVGLoader';

const FireIcon = ({ ...rest }) => {
  return (
    <SVGLoader {...rest}>
      <path d="M34.66,34.76a1.47,1.47,0,0,0-1.57-.12C29,36.58,25.23,42,23.29,48.92,20.63,58.23,22,67.66,26.8,75a30.26,30.26,0,0,0,22.62,12.7,18.87,18.87,0,0,0,2.66.12A28.33,28.33,0,0,0,73,79.15c7.5-8.1,9.43-23-1.33-35.92-1.58-1.93-3.15-3.63-4.6-5.32-7.5-8.35-12.34-13.67-7.14-26.13a1.49,1.49,0,0,0-.24-1.57,1.52,1.52,0,0,0-1.57-.48C46,13.48,41.19,21.1,43.49,33.19c.12.73.37,1.57.61,2.54C45.43,40.81,46,44.44,42.4,46a2.85,2.85,0,0,1-3.14-.6,11.53,11.53,0,0,1-3.87-9.07A2.72,2.72,0,0,0,34.66,34.76ZM63.09,69.84c-1.21,3-6.05,4.23-10,3.87-3.27-.24-5.81-1.57-6.17-3.14-.73-3.27,1.09-5.33,4-8.35,2.3-2.42,4.84-5,5.81-8.83C60.55,57.62,65.63,63.79,63.09,69.84Z" />
    </SVGLoader>
  );
};

export default FireIcon;
