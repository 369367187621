import styled from 'styled-components';
import { Box, Text, Button } from '@rsos/components/capstone/base';

export const Label = styled(Box).attrs({
  color: 'secondaryText',
  pb: 2,
})`
  opacity: ${p => (p.isDisabled ? 0.4 : 1)};
`;

export const ResultWrapper = styled(Box).attrs({
  horizontal: true,
  align: 'center',
  position: 'relative',
  width: '100%',
  cursor: 'pointer',
  padding: '10px',
  height: '40px',
})`
  user-select: none;
  max-width: ${({ maxWidth }) => maxWidth}px;
  ${({ isHovered, theme }) =>
    isHovered && `background-color: ${theme.colors.highlights}`};

  &:hover {
    background-color: ${({ theme }) => theme.colors.highlights};
  }
`;

export const Title = styled(Text).attrs({
  size: 'normal',
  inline: true,
  color: 'secondaryText',
})`
  padding-left: 10px;
`;

export const ResultsContainer = styled(Box).attrs(({ isVisible }) => ({
  display: isVisible ? 'block' : 'none',
  position: 'absolute',
  p: 0,
  my: '-10px',
  bg: 'background',
  width: '100%',
  color: 'secondaryText',
  border: '1px solid',
  borderColor: 'heavyLineOutline',
  borderRadius: '4px',
  maxWidth: '100%',
  maxHeight: '116px',
}))`
  overflow: auto;
  list-style: none;
  top: calc(100% - 10px);
  z-index: 20;
`;
export const TooltipChild = styled(Box).attrs(() => ({
  position: 'absolute',
  color: 'primaryText',
  bg: 'background',
  borderRadius: '5px',
  p: '10px',
  align: 'start',
}))`
  display: none;
  white-space: nowrap;
  top: 100%;
  width: max-content;
`;

export const IconWrapper = styled(Box).attrs(() => ({
  color: 'secondaryText',
}))`
  cursor: pointer;
`;
export const ButtonContainer = styled(Box).attrs(() => ({
  width: '100%',
}))``;
export const CallTakerModeButton = styled(Button).attrs(() => ({}))`
  position: relative;
  &:hover ${TooltipChild} {
    display: flex;
  }
`;

export const ErrorText = styled(Text).attrs({
  color: 'primaryText',
  size: 'small',
  py: 1,
})``;

export const NoResultText = styled(Text).attrs({
  color: 'primaryText',
  size: 'normal',
  fontWeight: 'bold',
  px: 2,
})``;
