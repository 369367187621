import React from 'react';
import SVGLoader from './SVGLoader';

const ChevronRightIcon = ({ ...rest }) => {
  return (
    <SVGLoader {...rest}>
      <path d="M13 8.50002C12.9987 8.28212 12.9518 8.06692 12.8622 7.86827C12.7726 7.66963 12.6425 7.49195 12.48 7.34668L5.04671 0.806684C4.92777 0.68657 4.7854 0.592189 4.62846 0.5294C4.47151 0.466611 4.30333 0.436756 4.13437 0.441687C3.9654 0.446618 3.79925 0.486231 3.64623 0.558066C3.49321 0.6299 3.3566 0.732423 3.24486 0.859269C3.13313 0.986115 3.04866 1.13458 2.99671 1.29543C2.94475 1.45629 2.92642 1.62611 2.94285 1.79435C2.95928 1.96259 3.01011 2.12566 3.0922 2.27343C3.17429 2.4212 3.28588 2.55051 3.42004 2.65335L9.92004 8.37335C9.93834 8.389 9.95303 8.40842 9.9631 8.43029C9.97317 8.45215 9.97839 8.47594 9.97839 8.50002C9.97839 8.52409 9.97317 8.54788 9.9631 8.56975C9.95303 8.59161 9.93834 8.61104 9.92004 8.62668L3.42004 14.3467C3.28588 14.4495 3.17429 14.5788 3.0922 14.7266C3.01011 14.8744 2.95928 15.0374 2.94285 15.2057C2.92642 15.3739 2.94475 15.5437 2.99671 15.7046C3.04866 15.8655 3.13313 16.0139 3.24486 16.1408C3.3566 16.2676 3.49321 16.3701 3.64623 16.442C3.79925 16.5138 3.9654 16.5534 4.13437 16.5583C4.30333 16.5633 4.47151 16.5334 4.62846 16.4706C4.7854 16.4078 4.92777 16.3135 5.04671 16.1934L12.48 9.65335C12.6425 9.50809 12.7726 9.33041 12.8622 9.13176C12.9518 8.93312 12.9987 8.71791 13 8.50002Z" />
    </SVGLoader>
  );
};

export default ChevronRightIcon;
