import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { selectProductTokenByName } from '@rsos/sinatra';
import { fetchOrgTokenSuccess } from '@rsos/sinatra/src/actions/fetchOrgToken';
import { refreshUserTokenSuccess } from '@rsos/sinatra/src/actions/refreshUserToken';
import { setTokens } from '@rsos/sinatra/src/utils/tokens';
import { getAPIHost, getProductName } from '@rsos/utils/metaTags';

const apiHost = getAPIHost();

export const TOKEN_TYPE_ACCESS_TOKEN = 'ACCESS_TOKEN';
export const TOKEN_TYPE_PRODUCT_TOKEN = 'PRODUCT_TOKEN';

const REFRESH_ACCESS_TOKEN_ENDPOINT = 'v1/scorpius/user/api-token-auth/refresh';
const getProductTokenEndpoint = state => {
  const orgID = state?.sinatra?.user?.currentOrg?.id;
  const productName = getProductName();
  return `v1/scorpius/organizations/${orgID}/token/?product=${productName}`;
};

/**
 * Returns product token and expiration for the current product.
 * @param {Object} sinatra - sinatra state
 */
export const getProductToken = sinatra => {
  const productName = getProductName();
  const {
    token: productToken,
    expiration: productTokenExpiration,
  } = selectProductTokenByName(sinatra, productName);
  return {
    productToken,
    productTokenExpiration,
  };
};

/**
 * Redux Toolkit baseQueryFn accepted args
 */
export const baseQueryArgs = {
  headers: {
    Authorization: `Bearer ${TOKEN_TYPE_ACCESS_TOKEN}`,
  },
  validateStatus: (response, result) => {
    return response.status >= 200 && response.status < 300;
  },
};
/**
 * Base extra options for RSOS API with RTK Query
 */
export const baseExtraOptions = {
  tokenType: TOKEN_TYPE_ACCESS_TOKEN,
};

/**
 * Allow for configuration of which token to use based on the actual
 * Authorization header value. This allows the retry/refresh logic to use the
 * updated store's token values compared to having to hardcode the new token
 * within the retry request.
 * @param {Object} prepareHeaders.headers
 * @param {Object} prepareHeaders.headers.Authorization - possible values are
 * `TOKEN_TYPE_ACCESS` and `TOKEN_TYPE_PRODUCT
 * @param {Object} api - destructured to getState, extra, endpoint, type, forced
 */
const baseQuery = fetchBaseQuery({
  baseUrl: apiHost,
  prepareHeaders: (headers, { getState }) => {
    const sinatra = getState().sinatra;

    if (!headers.get('Authorization')) {
      throw new Error('Must include Authorization header with your request');
    }

    // Use the product/org token
    if (headers.get('Authorization') === `Bearer ${TOKEN_TYPE_PRODUCT_TOKEN}`) {
      const bearerToken = getProductToken(sinatra).productToken;
      headers.set('Authorization', `Bearer ${bearerToken}`);
      return headers;
    }

    // Fall back to using the access token
    const token = sinatra?.auth?.tokens?.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

/**
 * Custom query with handling to refresh the access or product token based on
 * `extraOptions.tokenType`. It will refresh both if that isn't passed in to
 * the queryFn
 */
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const refreshToken = api.getState()?.sinatra?.auth?.tokens?.refresh_token;
    const userToken = api.getState()?.sinatra?.auth?.tokens?.token;

    const refreshAccessToken = async () => {
      const refreshAccessTokenResult = await baseQuery(
        {
          url: REFRESH_ACCESS_TOKEN_ENDPOINT,
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
          method: 'POST',
          body: { refresh_token: refreshToken },
        },
        api,
        extraOptions,
      );

      if (refreshAccessTokenResult.data) {
        const accessToken = refreshAccessTokenResult.data.token;
        api.dispatch(refreshUserTokenSuccess(accessToken));

        setTokens({
          token: accessToken,
          refresh_token: refreshToken,
        });
      }
    };

    const refreshProductToken = async () => {
      const refreshProductTokenResult = await baseQuery(
        {
          url: getProductTokenEndpoint(api.getState()),
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
        api,
        extraOptions,
      );
      if (refreshProductTokenResult.data) {
        const productToken = refreshProductTokenResult.data.token;
        api.dispatch(fetchOrgTokenSuccess(productToken));
      }
    };

    if (extraOptions?.tokenType) {
      if (extraOptions.tokenType === TOKEN_TYPE_ACCESS_TOKEN) {
        await refreshAccessToken();
      } else if (extraOptions.tokenType === TOKEN_TYPE_PRODUCT_TOKEN) {
        await refreshProductToken();
      }
    } else {
      // Refresh both tokens
      await refreshAccessToken();
      await refreshProductToken();
    }
    // TODO: if refreshing tokens fail, do we log the user out?

    // This will use the token it is supposed to for the retry
    result = await baseQuery(args, api, extraOptions);
  }
  return result;
};

/**
 * RTK automatic retry utility with exponential backoff. It gets triggered when
 * API requests fail with a 5xx status
 */
const staggeredBaseQuery = retry(baseQueryWithReauth, {
  maxRetries: 2,
});

export const rsosApi = createApi({
  reducerPath: 'api',
  baseQuery: staggeredBaseQuery,
  endpoints: () => ({}),
});
