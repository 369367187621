import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout, sessionEnd } from '@rsos/sinatra';
import { PORTAL_SESSION_PRIORITY } from '@rsos/capstone/src/constants/portalPriorities';
import { gspx } from '@rsos/capstone';
import { getSessionNotifyAge } from '@rsos/utils/metaTags';

import SessionModal from '../SessionModal';

const getWarningTimer = warning => {
  const sessionNotifyAge = getSessionNotifyAge();
  const mapper = {
    first: sessionNotifyAge,
    second: sessionNotifyAge - 300,
    final: 0,
    default: 0,
  };
  return mapper[warning] || mapper['default'];
};

const SessionPortal = () => {
  const [isActive, setIsActive] = useState(false);
  const [warning, setWarning] = useState(null);
  const dispatch = useDispatch();
  const session = useSelector(state => state.sinatra.auth.session);

  useEffect(() => {
    const { sessionStart } = session;
    let interval;

    if (sessionStart !== null) {
      interval = setInterval(() => {
        checkIsSessionExpiring();
      }, 1000);
    }
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIsSessionExpiring = () => {
    const { sessionExpiration } = session;
    const firstWarning = sessionExpiration - getSessionNotifyAge();
    const secondWarning = sessionExpiration - 300;
    const dateNowSeconds = Math.floor(Date.now() / 1000);
    if (dateNowSeconds > sessionExpiration) {
      handleLogout();
    } else if (dateNowSeconds === firstWarning) {
      setIsActive(true);
      setWarning('first');
    } else if (dateNowSeconds === secondWarning) {
      setIsActive(true);
      setWarning('second');
    } else if (dateNowSeconds === sessionExpiration) {
      setIsActive(true);
      setWarning('final');
    }
  };

  const handleLogout = () => {
    handleClose();
    localStorage.clear();
    dispatch(sessionEnd());
    gspx.trackCustomEvent('Auth Session', {
      name: 'Session End',
      Category: 'Session tracking',
      'Launched date': new Date(),
    });
    dispatch(logout());
    window.location = '/';
  };

  const handleClose = () => {
    setIsActive(false);
  };

  const timer = getWarningTimer(warning);

  return (
    <SessionModal
      isOpened={isActive}
      onLogout={handleLogout}
      onClose={handleClose}
      timer={timer}
      warning={warning}
      portalPriority={PORTAL_SESSION_PRIORITY}
    />
  );
};

export default SessionPortal;
