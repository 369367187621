import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { HeadphonesOn, HeadphonesOff } from '@rsos/assets/icons';
import { Box, Text, Switch } from '@rsos/components/capstone/base';
import { updateUserAttributes } from '@rsos/sinatra';
import { captureException } from '@rsos/utils/sentry';
import { notifyError } from '@rsos/utils/toastUtils';
import useIsIRP from '@rsos/utils/useIsIRP';
import { ctmDisableErrorMsg } from '../../CallTakerMode/constants';
import {
  trackCTMIconClicked,
  trackCTMEnabled,
} from '../../CallTakerMode/ctmTracking';

const arePropsEqual = (prevProps, nextProps) => {
  return prevProps.setIsCTMClicked === nextProps.setIsCTMClicked;
};

const CallTakerModeResponsive = memo(({ setIsCTMClicked }) => {
  const dispatch = useDispatch();

  const { irpVersion } = useIsIRP();
  const { user } = useSelector(state => state?.sinatra);

  const applicationName = user?.currentRole?.application;
  const ctmSettings =
    user?.profile?.attributes?.[applicationName]?.call_taker_mode_settings;

  const handleCallTakerMode = async () => {
    const positionID = ctmSettings?.workstation_position ?? null;

    if (ctmSettings?.is_enabled) {
      const data = {
        call_taker_mode_settings: {
          ...ctmSettings,
          is_enabled: false,
        },
      };
      try {
        await dispatch(updateUserAttributes(applicationName, data));
        trackCTMEnabled(false, positionID, irpVersion);
      } catch (err) {
        notifyError(ctmDisableErrorMsg);
        captureException(err);
      }
    } else {
      trackCTMIconClicked(positionID, irpVersion);
      setIsCTMClicked(true);
    }
  };

  return (
    <Box
      horizontal
      align="center"
      color="primaryText"
      justify="space-between"
      width="100%"
    >
      <Box align="center" horizontal flow={10}>
        {ctmSettings?.is_enabled ? (
          <HeadphonesOn
            viewboxWidth={18}
            viewboxHeight={18}
            width={18}
            height={18}
            color="success"
          />
        ) : (
          <HeadphonesOff
            viewboxWidth={18}
            viewboxHeight={18}
            width={18}
            height={18}
            color="secondaryLink"
          />
        )}
        <Text size="small" ml="7px" mr="5px">
          Call Taker Mode
        </Text>
      </Box>
      <Switch
        onOffLabel
        isOn={ctmSettings?.is_enabled ? true : false}
        onClick={handleCallTakerMode}
        toggleHeight="14px"
        toggleWidth="14px"
        wrapperHeight="18px"
        wrapperWidth="29px"
        toggleLeft="1px"
        toggleBottom="2px"
      />
    </Box>
  );
}, arePropsEqual);

export default withTheme(CallTakerModeResponsive);
