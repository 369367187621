import { Form } from 'formik';
import styled from 'styled-components';
import { Button, Box, Text } from '@rsos/components/capstone/base';
import { StyledInput as BaseStyledInput } from '@rsos/components/capstone/base/form/inputs/InputText/InputText.styles';

const NAV_HEIGHT = 52;
const LOGIN_WRAPPER_WIDTH = 280;
const LOGIN_WRAPPER_PADDING_TOP = 200;
const IMAGE_SIZE = 160;

/**
 * StyledPage is needed to override the default page background color and height
 * due to Bulma defaults. Can be removed once we remove Bulma.
 */
export const StyledPage = styled.div`
  && {
    html &,
    body & {
      background: ${({ theme }) => theme.colors.foreground};
      padding: 0;
      margin: 0;
      height: calc(100vh - ${NAV_HEIGHT}px);
    }
  }
`;

export const LoginWrapper = styled(Box)`
  width: ${LOGIN_WRAPPER_WIDTH}px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding-top: ${LOGIN_WRAPPER_PADDING_TOP}px;
`;

export const LoginImage = styled.img`
  width: ${IMAGE_SIZE}px;
  height: ${IMAGE_SIZE}px;
`;

export const LoginButton = styled(Button).attrs({
  btnType: 'primary',
  type: 'submit',
  noMargin: true,
  size: 'large',
  grow: true,
})``;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 60px;
`;
