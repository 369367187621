import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withTheme } from 'styled-components';
import { ThemeControlIcon } from '@rsos/assets/icons';
import { Box, Text, Switch } from '@rsos/components/capstone/base';
import { selectTheme } from '@rsos/sinatra';
import useIsIRP from '@rsos/utils/useIsIRP';
import { trackThemeControlClicks } from '../../../../common/ThemeControl/themeControlTrackings';

const ThemeSettings = withTheme(({ theme }) => {
  const { irpVersion } = useIsIRP();
  const { name: themeName = '' } = theme.colors;
  const [themeLocal, setTheme] = useState(themeName);
  const dispatch = useDispatch();

  const toggleSwitch = () => {
    const themeToSwitchTo = themeLocal === 'light' ? 'dark' : 'light';
    setTheme(themeToSwitchTo);
    trackThemeControlClicks(themeToSwitchTo, irpVersion);
    dispatch(selectTheme(themeToSwitchTo));
  };

  return (
    <Box
      horizontal
      align="center"
      color="primaryText"
      justify="space-between"
      width="100%"
    >
      <Box align="center" horizontal flow={10}>
        <ThemeControlIcon
          viewboxWidth={24}
          viewboxHeight={24}
          width={18}
          height={18}
          color="secondaryLink"
        />
        <Text size="small" ml="7px" mr="5px">
          Dark Mode
        </Text>
      </Box>
      <Switch
        onOffLabel
        isOn={themeLocal === 'dark' ? true : false}
        onClick={toggleSwitch}
        toggleHeight="14px"
        toggleWidth="14px"
        wrapperHeight="18px"
        wrapperWidth="29px"
        toggleLeft="1px"
        toggleBottom="2px"
      />
    </Box>
  );
});

export default ThemeSettings;
