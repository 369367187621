import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { alertUpdateSoundFile } from '@rsos/assets/audio';
import {
  JURISDICTION_VIEW,
  ALERTS_NOTIFICATION,
} from '@rsos/capstone/src/constants/capabilities';
import { LayoutContext } from '@rsos/components/capstone/base/Layout';
import { BP_LAPTOP } from '@rsos/constants/breakpoints';
import {
  resetIDWithSound,
  setAlertsWithRepeat,
} from '@rsos/reduxDataStore/src/features/alerts/incidentsSlice';
import { setTT911Sounds } from '@rsos/reduxDataStore/src/features/emergencyCalls/emergencyCallsSlice';
import { filteredAlertsSelector } from '@rsos/reduxDataStore/src/features/selectors/alertsSelectors';
import { callQueueSelector } from '@rsos/reduxDataStore/src/features/selectors/callQueueSelector';
import checkAgent511Access from '@rsos/utils/checkAgent511Access';
import checkAniAliAccessSelector from '@rsos/utils/checkAniAliAccessSelector';
import { checkObjStructure } from '@rsos/utils/tt911SoundSettingsHelpers';
import useCallTakerModePerms from '@rsos/utils/useCallTakerModePerms';
import usePrevious from '@rsos/utils/usePrevious';
import SettingsNonResponsive from './SettingsNonResponsive';
import SettingsResponsive from './SettingsResponsive';
import {
  getSoundFilePath,
  handleAudioPlayback,
  setUpTicker,
  mockOrgSettings,
  mockUserSettings,
  getFilteredAudioSettings,
  getAudioTone,
  handleWithInterval,
  getTT911EventInitialState,
} from './helpers';

const alertUpdateSound = new Audio(alertUpdateSoundFile);
const ALERTS = 'ALERTS';

const Settings = ({ path, themeName, setIsCTMClicked }) => {
  const { layout } = useContext(LayoutContext);
  const dispatch = useDispatch();
  const sinatra = useSelector(state => state.sinatra);
  const psaps = useSelector(state => state.psaps);
  const hasAgent511Access = checkAgent511Access(psaps, sinatra);
  const ctmPermission = useCallTakerModePerms();

  // General selectors
  const { id: orgID } = useSelector(state => state.sinatra.user.currentOrg);
  const { application: applicationName } = useSelector(
    state => state.sinatra.user.currentRole,
  );

  const isJVEnabled = useSelector(
    state =>
      !!(
        state.psaps.currentPSAP &&
        state.psaps.currentPSAP.activeCapabilities &&
        state.psaps.currentPSAP.activeCapabilities[JURISDICTION_VIEW]
      ),
  );
  const resolvedOrgAudioSettings = useSelector(
    state =>
      state.sinatra.user.profile?.organizations?.find(o => o.id === orgID)
        ?.attributes[applicationName]?.audio_settings || mockOrgSettings,
  );
  const orgAudioSettings = useMemo(
    () =>
      checkObjStructure(resolvedOrgAudioSettings, [
        hasAgent511Access && 'tt911OrgAudioSettings',
      ]),
    [resolvedOrgAudioSettings, hasAgent511Access],
  );

  const resolvedUserAudioSettings = useSelector(
    state =>
      state.sinatra.user.profile?.attributes?.[applicationName]
        ?.audio_settings || mockUserSettings,
  );
  const userAudioSettings = useMemo(
    () =>
      checkObjStructure(resolvedUserAudioSettings, [
        hasAgent511Access && 'tt911UserAudioSettings',
      ]),
    [resolvedUserAudioSettings, hasAgent511Access],
  );

  // Alerts selectors
  const alerts = useSelector(state => filteredAlertsSelector(state.incidents));
  const { IDWithSound, audibleNotificationIDs, selectedAlertID } = useSelector(
    state => state.incidents,
  );

  const isDisplayAlerts = useSelector(state => {
    return (
      state.sinatra.user?.currentRole?.permissions?.includes(ALERTS) ||
      state.psaps.currentPSAP?.activeCapabilities?.[ALERTS_NOTIFICATION]
    );
  });

  const filteredOrgAudioSettings = getFilteredAudioSettings(
    orgAudioSettings,
    isJVEnabled,
    isDisplayAlerts,
    hasAgent511Access,
  );

  let filteredUserAudioSettings = getFilteredAudioSettings(
    userAudioSettings,
    isJVEnabled,
    isDisplayAlerts,
    hasAgent511Access,
  );

  // Location selectors
  const { locationType, selectedCallerID, tt911Sounds = {} } = useSelector(
    state => state.emergencyCalls,
  );

  // volume variables
  const volumeAlertsValue = orgAudioSettings.alerts.sound_on
    ? userAudioSettings.alerts.volume
    : 0;
  const volumeCallsValue = orgAudioSettings.calls.sound_on
    ? userAudioSettings.calls.volume
    : 0;
  const volumeSMSValue = orgAudioSettings.sms.sound_on
    ? userAudioSettings.sms.volume
    : 0;
  const volumeTT911Value = orgAudioSettings.tt911?.sound_on
    ? userAudioSettings.tt911?.volume
    : 0;
  // alert specific variables
  const previousIDWithSound = usePrevious(IDWithSound);
  const isFullAlertRepeatSound = orgAudioSettings.alerts.repeat;
  const numAlertNotifications = audibleNotificationIDs
    ? audibleNotificationIDs.length
    : 0;
  let updateMessageAudio = alertUpdateSound;
  updateMessageAudio.volume = volumeAlertsValue;

  // local state variables
  const [
    numCurrentAlertNotifications,
    setNumCurrentAlertNotifications,
  ] = useState(numAlertNotifications);
  const [numFilteredLocations, setNumFilteredLocations] = useState(0);

  const hasAniAliAccess = useSelector(state =>
    checkAniAliAccessSelector({ psaps: state.psaps, sinatra: state.sinatra }),
  );
  // location specific variables
  const filteredCalls = useSelector(state =>
    callQueueSelector(state.emergencyCalls.callQueue, hasAniAliAccess),
  );

  const callQueueLength = Object.keys(filteredCalls).length || 0;
  const previousCallQueueLength = usePrevious(callQueueLength);

  // alert specific useEffect hooks
  useEffect(() => {
    //when a new alert comes in, it plays either a single sound or starts playing a repeatable notification and sets the timer for the loop to finish when the alert is timed out
    const alertsTone = orgAudioSettings.alerts.tone;
    let selectedAudio = getSoundFilePath(alertsTone);
    selectedAudio.volume = volumeAlertsValue;
    const alert = alerts[IDWithSound];
    if (
      volumeAlertsValue > 0 &&
      IDWithSound &&
      alert?.location_history?.length === 1 &&
      IDWithSound !== previousIDWithSound
    ) {
      if (!alert.supplemental_only && isFullAlertRepeatSound) {
        dispatch(
          setAlertsWithRepeat({
            alertID: alert.alert_id,
            audio: selectedAudio,
          }),
        );
        return setUpTicker(alert, selectedAudio, alertsTone, () =>
          dispatch(resetIDWithSound()),
        );
      }

      handleAudioPlayback(selectedAudio, alertsTone);

      if (IDWithSound && selectedAlertID && IDWithSound !== selectedAlertID) {
        // Reset `IDWithSound` after sound is played for new alerts that don't
        // match the `selectedAlertID`
        dispatch(resetIDWithSound());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IDWithSound]);

  useEffect(() => {
    numAlertNotifications
      ? setNumCurrentAlertNotifications(numAlertNotifications)
      : setNumCurrentAlertNotifications(0);
  }, [numAlertNotifications]);

  useEffect(() => {
    //watches for new chat messages and adds a notification
    if (
      volumeAlertsValue > 0 &&
      numAlertNotifications > numCurrentAlertNotifications
    ) {
      handleAudioPlayback(updateMessageAudio, 'alertUpdate');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numAlertNotifications, numCurrentAlertNotifications]);

  const handleSetTT911Sounds = (key, data) => {
    dispatch(
      setTT911Sounds({
        phoneNumber: key,
        data: data,
      }),
    );
  };

  useEffect(() => {
    if (selectedCallerID) {
      const tt911Event = tt911Sounds[selectedCallerID];
      if (tt911Event) {
        clearInterval(tt911Sounds[selectedCallerID]?.interval);
        handleSetTT911Sounds(selectedCallerID, { status: 'finish' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCallerID]);

  const currentTT911Events = Object.keys(tt911Sounds) || [];
  const prevTT911Events = usePrevious(currentTT911Events) || [];

  useEffect(() => {
    if (currentTT911Events.length > prevTT911Events.length) {
      let repeatTimes = orgAudioSettings.tt911.repeat_times;
      const val = Object.entries(tt911Sounds).pop()[1];
      if (val.status === 'idle') {
        // if repeat is on -> call handleWithInterval, if not - just audio play 1 time
        if (orgAudioSettings.tt911.repeat) {
          handleWithInterval(
            val,
            repeatTimes,
            tt911Sounds,
            handleSetTT911Sounds,
            orgAudioSettings.tt911.tone,
            volumeTT911Value,
          );
        } else {
          const tt911Tone = orgAudioSettings.tt911.tone;
          let audio = getSoundFilePath(tt911Tone);
          audio.volume = volumeTT911Value;
          handleAudioPlayback(audio, tt911Tone);
          handleSetTT911Sounds(val.phoneNumber, { status: 'finish' });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tt911Sounds]);

  const filteredIsTT911 = useMemo(
    () =>
      Object.entries(filteredCalls)
        .map(([key, value]) => value.isTT911 && key)
        .filter(i => i !== false),
    [filteredCalls],
  );

  useEffect(() => {
    if (hasAgent511Access && filteredIsTT911.length !== 0) {
      filteredIsTT911.forEach(key => {
        if (!tt911Sounds[key]) {
          handleSetTT911Sounds(key, getTT911EventInitialState(key));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredIsTT911]);

  useEffect(() => {
    const selectedAudio = getAudioTone(
      locationType,
      orgAudioSettings,
      hasAgent511Access,
    );

    const volumeVal =
      locationType === 'sms'
        ? hasAgent511Access
          ? volumeTT911Value
          : volumeSMSValue
        : volumeCallsValue;

    const selectedAudioName = selectedAudio.audioName;

    const selectedAudioElement = selectedAudio.audioElement;

    selectedAudioElement.volume = volumeVal;

    if (
      isJVEnabled &&
      volumeVal > 0 &&
      numFilteredLocations &&
      numFilteredLocations > 0
    ) {
      handleAudioPlayback(selectedAudioElement, selectedAudioName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numFilteredLocations]);

  useEffect(() => {
    if (callQueueLength > previousCallQueueLength) {
      handleFilteredLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callQueueLength]);

  const handleFilteredLocation = () => {
    setNumFilteredLocations(numFilteredLocations => numFilteredLocations + 1);
  };

  return layout.window.width <= BP_LAPTOP ? (
    <SettingsResponsive
      path={path}
      userAudioSettings={filteredUserAudioSettings}
      orgAudioSettings={filteredOrgAudioSettings}
      setIsCTMClicked={setIsCTMClicked}
      isCTMEnabled={ctmPermission}
    />
  ) : (
    <SettingsNonResponsive
      data-name="navigation-audio-control"
      path={path}
      themeName={themeName}
      userAudioSettings={filteredUserAudioSettings}
      orgAudioSettings={filteredOrgAudioSettings}
      setIsCTMClicked={setIsCTMClicked}
      isCTMEnabled={ctmPermission}
    />
  );
};

export default Settings;
