import { isDevelopmentEnv } from './index';

/**
 * Calls the given function supressing any errors.
 * The purpose of this function is to wrap 3rd party calls that could
 * potentially crash the application if misconfigured or breaking changes are
 * introduced. This is the only use-case for this function and should not be
 * used to wrap other code.
 * @param {Function} fn - The function to call.
 */
const safeCall = fn => (...args) => {
  try {
    return fn(...args);
  } catch (error) {
    /* suppress */
    if (isDevelopmentEnv()) {
      // eslint-disable-next-line no-console
      console.error('[SAFECALL]:', error);
    }
  }
};

export default safeCall;
