import React, { useEffect, useRef } from 'react';
import FlvJs from 'flv.js';
import Hls from 'hls.js';
import PropTypes from 'prop-types';

export const HLS_EXTENSIONS = /\.(m3u8)($|\?)/i;
export const FLV_EXTENSIONS = /\.(flv)($|\?)/i;

/**
 * Enchanced Video tag to load livestreamed videos.
 * Currently supports are the native video extensions plus HLS (.m3u8).
 *
 * @params every param is the same as the video tag aside from
 *
 * @param {string} playerId - the id for the video player
 * @param {string} format - explicitly state if an format to avoid infering from url
 * @param {object} [playerRef] - the ref for the player
 */
const VideoPlayer = ({
  playerId,
  src,
  autoPlay = false,
  muted,
  controls,
  loop,
  preload,
  height = '100%',
  width = '100%',
  playerRef,
  onPlay,
  onPause,
  onVolumeChange,
  onKeyUp,
  authToken,
  format = '',
  onFatalError,
  controlsList = '',
  disablePictureInPicture = false,
}) => {
  const hls = useRef(null);
  const flv = useRef(null);

  const setupVideo = () => {
    const video = document.getElementById(playerId);
    if (format === 'hls' || (!format && HLS_EXTENSIONS.test(src))) {
      // API Docs: https://github.com/video-dev/hls.js/blob/master/docs/API.md
      if (Hls.isSupported()) {
        hls.current = new Hls({
          ...(authToken && {
            xhrSetup: xhr => {
              xhr.setRequestHeader('Authorization', `Bearer ${authToken}`);
            },
          }),
        });

        hls.current.loadSource(src);
        hls.current.attachMedia(video);

        // For this event, data will return an object with the following important keys
        // fatal (did it crash), details (specific error), and type (error category)
        hls.current.on(Hls.Events.ERROR, (error, data) => {
          if (onFatalError && data.fatal) {
            onFatalError(data);
          }
        });
      }
      // HLS.js is not supported on platforms that do not have Media Source
      // Extensions (MSE) enabled.
      //
      // When the browser has built-in HLS support (check using `canPlayType`),
      // we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video
      // element through the `src` property. This is using the built-in support
      // of the plain video element, without using HLS.js.
      //
      // Note: it would be more normal to wait on the 'canplay' event below however
      // on Safari (where you are most likely to find built-in HLS support) the
      // video.src URL must be on the user-driven white-list before a 'canplay'
      // event will be emitted; the last video event that can be reliably
      // listened-for when the URL is not on the white-list is 'loadedmetadata'.
      else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = src;
      }
    } else if (format === 'flv' || (!format && FLV_EXTENSIONS.test(src))) {
      // API Docs: https://github.com/Bilibili/flv.js/blob/master/docs/api.md
      FlvJs.LoggingControl.enableVerbose = false;
      flv.current = FlvJs.createPlayer(
        {
          type: 'flv',
          url: src,
          isLive: true,
          duration: 5000,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      flv.current.on(FlvJs.Events.ERROR, event => {
        onFatalError(event);
      });

      flv.current.attachMediaElement(document.getElementById(playerId));
      flv.current.load();
    } else {
      video.src = src;
    }
  };

  const removeVideo = () => {
    if (hls.current) {
      hls.current.destroy();
    }
    if (flv.current) {
      flv.current.destroy();
    }
  };

  useEffect(() => {
    setupVideo();

    return () => removeVideo;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return (
    <video
      id={playerId}
      ref={playerRef}
      muted={muted}
      autoPlay={autoPlay}
      controls={controls}
      loop={loop}
      preload={preload}
      height={height}
      width={width}
      onPlay={onPlay}
      onPause={onPause}
      onVolumeChange={onVolumeChange}
      onKeyUp={onKeyUp}
      controlsList={controlsList}
      disablePictureInPicture={disablePictureInPicture}
    ></video>
  );
};

VideoPlayer.propTypes = {
  playerId: PropTypes.string.isRequired,
  src: PropTypes.string,
  muted: PropTypes.bool,
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  loop: PropTypes.bool,
  preload: PropTypes.oneOf(['none', 'metadata', 'auto', '']),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  playerRef: PropTypes.object,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onVolumeChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  authToken: PropTypes.string,
  format: PropTypes.oneOf(['hls', 'flv', '']),
  onFatalError: PropTypes.func,
  controlsList: PropTypes.string,
  disablePictureInPicture: PropTypes.bool,
};

export default VideoPlayer;
