/** utility function to determine if a sidebar item should be rendered
 * @param {Object} sidebarItem - sidebar full object
 * @param {Object} flags - feature flags object
 * @param {Object} activeCapabilities - object representing active org capabilities
 * @param {Array} pageAccessList - an array of user permissions
 * @returns boolean determining if eligible for rendering
 */

export const shouldSideBarItemRender = (
  sidebarItem,
  flags = {},
  activeCapabilities = {},
  pageAccessList = [],
) => {
  const {
    flagAccess = undefined,
    capabilityAccess = undefined,
    beName = '',
  } = sidebarItem;

  /** check if the sidebarItem has flagAccess property and it is enabled */
  const isFeatureFlagEnabled = flagAccess ? flags[flagAccess] : true;
  /** check if the sidebarItem has capabilityAccess property and if it is enabled */
  const isCapabilityAllowed =
    !capabilityAccess ||
    (capabilityAccess &&
      Object.keys(activeCapabilities) &&
      Object.keys(activeCapabilities).includes(capabilityAccess));
  /** check if the sidebarItem has page permission access configured */
  const isPageAccessPermitted = pageAccessList.some(
    pageAccess => pageAccess.name === beName,
  );

  return !!(
    isFeatureFlagEnabled &&
    isPageAccessPermitted &&
    isCapabilityAllowed
  );
};
