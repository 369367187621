import { gspx } from '@rsos/capstone';

// when user turns on/off
export const trackCTMEnabled = (isEnabled, positionID, irpVersion) => {
  gspx.trackCustomEvent('CallTakerMode', {
    irpVersion,
    name: `${isEnabled ? 'Enabled' : 'Disabled'} Call Taker Mode`,
    positionID,
    Category: 'Call Taker Mode',
    'Launched date': new Date(),
  });
};

// when Call Taker Mode Icon is clicked
// clicking the icon does not automatically enables CTM,
// it opens up positionSelectionModal if its already disabled
export const trackCTMIconClicked = (positionID, irpVersion) => {
  gspx.trackCustomEvent('CallTakerMode', {
    irpVersion,
    name: `Call Taker Mode Icon clicked`,
    positionID,
    Category: 'Call Taker Mode',
    'Launched date': new Date(),
  });
};

// when a call comes in and Call Taker Mode auto opens it
export const trackCTMAcceptedCall = (
  positionID,
  irpVersion,
  payloadID,
  requestID,
) => {
  gspx.trackCustomEvent('CallTakerMode', {
    irpVersion,
    name: `Automatically opened a call via Call Taker Mode`,
    positionID,
    Category: 'Call Taker Mode',
    'Launched date': new Date(),
    payloadID,
    requestID,
  });
};
