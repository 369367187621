import darkModeDefinition from './darkModeDefinition';
import icspThemeDefinition from './icspThemeDefinition';
import lightModeDefinition from './lightModeDefinition';
import themeDefinition from './themeDefinition';

const genericFields = modeName => {
  return {
    name: modeName,
    backdropOverlayBg: '#0a0a0adb',
  };
};
const getThemeDefinitionByMode = (mode, modeName) => {
  return {
    ...genericFields(modeName),
    ...themeDefinition(mode.palette, mode.fieldsToOverride),
  };
};
export const modes = {
  light: lightModeDefinition,
  dark: darkModeDefinition,
  icsp: icspThemeDefinition,
  default: lightModeDefinition,
};

export function resolveThemeColors(mode) {
  return modes[mode]
    ? getThemeDefinitionByMode(modes[mode], mode)
    : getThemeDefinitionByMode(modes['default'], 'light');
}
