import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { HeadphonesOn, HeadphonesOff } from '@rsos/assets/icons';
import { Text } from '@rsos/components/capstone/base';
import { updateUserAttributes } from '@rsos/sinatra';
import { captureException } from '@rsos/utils/sentry';
import { notifyError } from '@rsos/utils/toastUtils';
import useIsIRP from '@rsos/utils/useIsIRP';
import {
  TooltipChild,
  IconWrapper,
  CallTakerModeButton,
} from './Modals/Modal.styles';
import { ctmDisableErrorMsg } from './constants';
import { trackCTMIconClicked, trackCTMEnabled } from './ctmTracking';

const arePropsEqual = (prevProps, nextProps) => {
  return prevProps.setIsCTMClicked === nextProps.setIsCTMClicked;
};

const CallTakerMode = memo(({ setIsCTMClicked }) => {
  const dispatch = useDispatch();

  const { user } = useSelector(state => state?.sinatra);

  const applicationName = user?.currentRole?.application;
  const ctmSettings =
    user?.profile?.attributes?.[applicationName]?.call_taker_mode_settings;

  const { irpVersion } = useIsIRP();

  const handleCallTakerMode = async () => {
    const positionID = ctmSettings?.workstation_position || null;

    if (ctmSettings?.is_enabled) {
      const data = {
        call_taker_mode_settings: {
          ...ctmSettings,
          is_enabled: false,
        },
      };
      try {
        await dispatch(updateUserAttributes(applicationName, data));
        trackCTMEnabled(false, positionID, irpVersion);
      } catch (err) {
        notifyError(ctmDisableErrorMsg);
        captureException(err);
      }
    } else {
      trackCTMIconClicked(positionID, irpVersion);
      setIsCTMClicked(true);
    }
  };

  return (
    <CallTakerModeButton
      btnType="tertiary"
      size="small"
      onClick={handleCallTakerMode}
      data-name={`call-taker-mode-${ctmSettings?.is_enabled ? 'on' : 'off'}`}
    >
      <IconWrapper>
        {ctmSettings?.is_enabled ? (
          <HeadphonesOn
            viewboxWidth={16}
            viewboxHeight={18}
            width={18}
            height={16}
            color="success"
          />
        ) : (
          <HeadphonesOff
            viewboxWidth={16}
            viewboxHeight={18}
            width={18}
            height={16}
            color="secondaryLink"
          />
        )}
      </IconWrapper>
      <TooltipChild>
        {ctmSettings?.is_enabled ? (
          <>
            <Text>Call Taker Mode: On</Text>
            <Text>{`Seat Position: ${ctmSettings.workstation_position}`}</Text>
          </>
        ) : (
          `Call Taker Mode: Off`
        )}
      </TooltipChild>
    </CallTakerModeButton>
  );
}, arePropsEqual);

export default withTheme(CallTakerMode);
