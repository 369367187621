/**
 * returns a list of strings that includes the targetValue
 * @param {array} arr - list of strings
 * @param {string} targetValue - the value to check against for each str inside arr
 *
 */

export const reduceMatchingTerms = (arr, targetValue) => {
  const result = arr.reduce((filtered, option) => {
    if (
      option
        .toLocaleLowerCase()
        .trim()
        .includes(targetValue.toLowerCase().trim())
    ) {
      filtered.push(option);
    }
    return filtered;
  }, []);
  return result;
};
