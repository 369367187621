import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Box, Button, Text } from '@rsos/components/capstone/base';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@rsos/components/capstone/base/Modal/ModalComponents';
import { updateUserAttributes } from '@rsos/sinatra';
import { captureException } from '@rsos/utils/sentry';
import { notifyError } from '@rsos/utils/toastUtils';
import { ctmEnableErrorMsg } from '../constants';
import { Label, ButtonContainer } from './Modal.styles';

const InitiationModal = ({
  isOpened,
  closeIntiationModal,
  handleTurnOnClick,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.sinatra);
  const applicationName = user.currentRole.application;

  /**
   * First time logging in with CallTakerMode permission enabled
   * but does not have call_taker_mode_settings in sinatra
   * preset call_taker_mode_settings in sinatra will prevent future prompt of this modal
   */
  useEffect(() => {
    const data = {
      call_taker_mode_settings: {
        is_enabled: false,
        workstation_position: '',
      },
    };
    try {
      dispatch(updateUserAttributes(applicationName, data));
    } catch (err) {
      notifyError(ctmEnableErrorMsg);
      captureException(err);
    }
  }, [applicationName, dispatch]);

  const handleTurnOnCTM = () => {
    handleTurnOnClick();
    closeIntiationModal();
  };

  return (
    <Modal
      id="initial-modal"
      isOpened={isOpened}
      onClose={closeIntiationModal}
      width={350}
    >
      <ModalHeader onClose={closeIntiationModal}>
        <Box mx="5px">
          <Text size="xlarge" color="primaryText">
            New Feature: Call-Taker Mode
          </Text>
        </Box>
      </ModalHeader>

      <ModalBody justify="center" noFooter>
        <Box mx="5px" pb={'27px'}>
          <Box>
            <Label>
              <Text size="small">
                Call-taker mode introduces a way for telecommunicators to better
                access data. All calls that you answer from your seat position
                will automatically open the related event in the RapidSOS
                portal.
              </Text>
            </Label>
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter justify="center">
        <ButtonContainer>
          <Button btnType="primary" onClick={handleTurnOnCTM}>
            Turn on
          </Button>
          <Button btnType="tertiary" onClick={closeIntiationModal}>
            Cancel
          </Button>
        </ButtonContainer>
      </ModalFooter>
    </Modal>
  );
};

export default InitiationModal;
