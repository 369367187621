import styled from 'styled-components';
import Box from '@rsos/components/capstone/base/Box';
import { withLayout } from '@rsos/components/capstone/base/Layout';


export const MainContainer = withLayout(styled(Box).attrs({
  horizontal: true,
  bg: 'background',
})`
  height: ${({ layout }) => layout.body.height}px;
`);
export const AppWrapper = styled(Box).attrs({
  width: '100%',
  overflow: 'auto',
  bg: 'background',
})``;
export const AppInnerWrapper = styled(Box).attrs({
  px: 32,
  bg: 'inherit',
  pt: 30,
  height: '100%',
})`
  z-index: 0;
  max-width: 744px; //max-width of the content is 680px + px value from each side
`;

export const AppIaRWrapper = styled(Box).attrs({
  px: 32,
  bg: 'inherit',
  pt: 30,
  height: '100%',
})`
  z-index: 0;
  max-width: 844px; //max-width of the content is 780px + px value from each side
`;