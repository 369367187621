import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NoResultsIcon } from '@rsos/assets/icons';
import { Modal, Box, Button, Text } from '@rsos/components/capstone/base';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@rsos/components/capstone/base/Modal/ModalComponents';
import InputText from '@rsos/components/capstone/base/form/inputs/InputText';
import { updateUserAttributes } from '@rsos/sinatra';
import { reduceMatchingTerms } from '@rsos/utils/reduceMatchingTerms';
import { captureException } from '@rsos/utils/sentry';
import { notifyError } from '@rsos/utils/toastUtils';
import useIsIRP from '@rsos/utils/useIsIRP';
import { ctmEnableErrorMsg } from '../constants';
import { trackCTMEnabled } from '../ctmTracking';
import {
  Label,
  ButtonContainer,
  ResultWrapper,
  Title,
  ResultsContainer,
  ErrorText,
  NoResultText,
  IconWrapper,
} from './Modal.styles';

const PositionIDSelectionModal = ({
  isOpened,
  togglePositionIDSelectionModal,
  positionIDs,
}) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [hasError, setHasError] = useState(false);
  const { irpVersion } = useIsIRP();
  const { currentRole } = useSelector(state => state.sinatra.user);
  const applicationName = currentRole.application;

  useEffect(() => {
    const foundOption = positionIDs?.find(
      option =>
        option.toLocaleLowerCase().trim() === searchTerm.toLowerCase().trim(),
    );
    if (!foundOption && searchTerm) {
      const newSearchResult = reduceMatchingTerms(positionIDs, searchTerm);
      setSearchResults(newSearchResult);
      if (newSearchResult.length) {
        setHasError(false);
      } else {
        setHasError(true);
      }
    }
    if (foundOption || !searchTerm) {
      setHasError(false);
      setSearchResults([]);
    }
  }, [searchTerm, positionIDs]);

  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = async () => {
    const foundOption = positionIDs?.find(
      option =>
        option.toLocaleLowerCase().trim() === searchTerm.toLowerCase().trim(),
    );
    if (foundOption) {
      const data = {
        call_taker_mode_settings: {
          is_enabled: true,
          workstation_position: searchTerm,
        },
      };
      try {
        await dispatch(updateUserAttributes(applicationName, data));
        trackCTMEnabled(true, searchTerm, irpVersion);
      } catch (err) {
        notifyError(ctmEnableErrorMsg);
        captureException(err);
      } finally {
        togglePositionIDSelectionModal();
      }
    } else {
      // shouldnt hit this case since positionID are predefined and the error modal also blocks it
    }
  };

  return (
    <Modal
      id="position-id-selection-modal"
      isOpened={isOpened}
      onClose={togglePositionIDSelectionModal}
      width={400}
    >
      <ModalHeader onClose={togglePositionIDSelectionModal}>
        <Box mx="5px">
          <Text size="xlarge" color="primaryText">
            Call-Taker Mode
          </Text>
          <Text size="small" color="secondaryText" pt="13px">
            You are about to enter call-taker mode. In this mode, all calls that
            you answer from your seat position will automatically open the
            related event in RapidSOS portal.
          </Text>
        </Box>
      </ModalHeader>

      <ModalBody pt="19px" justify="center" noFooter>
        <Box mx="5px" position="relative">
          <Box pb={4}>
            <Label>
              <Text size="small" color="primaryText">
                Enter Position ID
              </Text>
            </Label>
            <InputText
              shouldDisplayClearButton={false}
              shouldDisplayErrorIcon={false}
              type="text"
              onChange={handleSearch}
              value={searchTerm}
              error={hasError}
            />
          </Box>
          <ResultsContainer isVisible={searchResults.length || hasError}>
            {hasError ? (
              <Box px={3} pb={3} pt={2}>
                <Box horizontal align="center" py={2}>
                  <IconWrapper>
                    <NoResultsIcon />
                  </IconWrapper>
                  <NoResultText>No Results</NoResultText>
                </Box>

                <ErrorText>{`0 Results found. Please make sure that the position ID is spelled correctly.`}</ErrorText>
              </Box>
            ) : (
              <>
                {searchResults.map(id => {
                  return (
                    <ResultWrapper key={id} onClick={() => setSearchTerm(id)}>
                      <Title>{id}</Title>
                    </ResultWrapper>
                  );
                })}
              </>
            )}
          </ResultsContainer>
        </Box>
      </ModalBody>
      <ModalFooter justify="center">
        <ButtonContainer horizontal>
          <Button
            grow
            btnType="primary"
            onClick={handleSubmit}
            disabled={!searchTerm}
          >
            Submit
          </Button>
        </ButtonContainer>
      </ModalFooter>
    </Modal>
  );
};

export default PositionIDSelectionModal;
