import { unwrapResult } from '@reduxjs/toolkit';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useHasLocationsToRender from 'emergency_data/src/hooks/useHasLocationsToRender';
import { getMostRecentLocations } from 'emergency_data/src/utils/getMostRecentLoc';
import { ExternalLinkIcon } from '@rsos/assets/icons';
import { Dropdown } from '@rsos/components/capstone/base';
import { LayoutContext } from '@rsos/components/capstone/base/Layout';
import {
  APP_LAUNCHER_AVIVE,
  APP_TYPE_APP_LAUNCH,
  APP_TYPE_CUSTOM,
  APP_TYPE_DATA_SHARE,
} from '@rsos/constants/appLauncherTypes';
import { BP_LAPTOP } from '@rsos/constants/breakpoints';
import {
  createDeeplinkURL,
  fetchAppLauncherApps,
  selectAppLauncherApp,
} from '@rsos/reduxDataStore/src/features/appLauncher/appLauncherSlice';
import { notifyDefault, notifyError } from '@rsos/utils/toastUtils';
import useIsIRP from '@rsos/utils/useIsIRP';
import AppLauncherButton from './AppLauncherButton';
import AppLauncherDropdown from './AppLauncherDropdown';
import AppLauncherToolbar from './AppLauncherToolbar';
import ConfirmationModal from './DataShare/ConfirmationModal';
import { trackAppLauncherClick } from './appLauncherTrackings';
import { handleAppData } from './utils';

/**
 * Main component for the app/tools launcher feature.
 */
export const AppLauncher = () => {
  const dispatch = useDispatch();
  const { layout } = useContext(LayoutContext);
  const { irpVersion } = useIsIRP();
  const sinatra = useSelector(state => state.sinatra);
  const orgID = sinatra.user.currentOrg.id;
  const currentOrgName = sinatra.user.currentOrg.name;
  const applications = useSelector(state => state.appLauncher.applications);
  const { selectedCallerID, callQueue } = useSelector(
    state => state.emergencyCalls,
  );
  const hasLocationsToRender = useHasLocationsToRender();
  const { mostRecentLocation } = getMostRecentLocations(
    callQueue[selectedCallerID],
  );
  const [isDataShareModalOpened, setIsDataShareModalOpened] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);

  // The number of tools shown in the toolbar is based on the `show_in_toolbar` flag
  // and have a limit of 5 tools.
  const toolbarApps = applications
    .filter(app => app.details.show_in_toolbar)
    .slice(0, 5);

  const handleOpenDataShareModal = app => {
    setIsDataShareModalOpened(true);
    setSelectedApp(app);
  };

  const handleCloseDataShareModal = () => {
    setIsDataShareModalOpened(false);
    setSelectedApp(null);
  };

  const handleCustomAppLauncherType = data => {
    dispatch(selectAppLauncherApp({ appName: data.name }));
  };

  const handleDeeplink = app => {
    const {
      id: appID,
      details: { data_fields, deeplink_endpoint: appEndpoint },
      display_name: appName,
    } = app;

    // appEndpoint is required to create a deeplink request
    if (!appEndpoint) {
      notifyError(
        `A required attribute (deeplink_endpoint) is missing for ${appName}`,
      );

      return;
    }

    let requestData = {
      appEndpoint,
      appID,
      appName,
      orgID,
    };

    if (data_fields?.length) {
      requestData = {
        ...requestData,
        appData: handleAppData(
          data_fields,
          selectedCallerID,
          mostRecentLocation,
        ),
      };
    }

    const toastParams = {
      message: `Opening ${requestData.appName} in new tab...`,
      customIcon: ExternalLinkIcon,
    };

    dispatch(createDeeplinkURL(requestData))
      .then(unwrapResult)
      .then(notifyDefault(toastParams))
      .then(response => {
        if (response?.url)
          window.open(response.url, '_blank', 'noopener noreferrer');
      })
      .catch(error => {
        notifyError(error);
      });
  };

  const handleApp = (app, source) => {
    trackAppLauncherClick(app, source, irpVersion);
    const { launch_global, launch_call } = app.details;
    if ((launch_call && selectedCallerID) || launch_global) {
      if (app.type === APP_TYPE_CUSTOM) handleCustomAppLauncherType(app);
      if (app.type === APP_TYPE_DATA_SHARE) handleOpenDataShareModal(app);
      if (app.type === APP_TYPE_APP_LAUNCH) handleDeeplink(app);
    }
  };

  useEffect(() => {
    if (currentOrgName) {
      dispatch(fetchAppLauncherApps(currentOrgName));
    }
  }, [currentOrgName, dispatch]);

  return (
    <>
      {/* Renders the app launcher dropdown menu. */}
      {applications?.length ? (
        <Dropdown triggerElement={AppLauncherButton} triggerOnClose>
          {onClose => (
            <AppLauncherDropdown
              applications={applications}
              onClose={onClose}
              onHandleApp={handleApp}
            />
          )}
        </Dropdown>
      ) : null}

      {/* Renders the app launcher toolbar shortcut buttons when a call is selected. */}
      {layout.window.width >= BP_LAPTOP &&
      selectedCallerID &&
      hasLocationsToRender &&
      toolbarApps?.length ? (
        <AppLauncherToolbar onHandleApp={handleApp} toolbarApps={toolbarApps} />
      ) : null}

      {/* Display the data share modal when the app is selected from the dropdown or toolbar. */}
      <ConfirmationModal
        isOpened={isDataShareModalOpened}
        onClose={handleCloseDataShareModal}
        selectedApp={selectedApp}
      />
    </>
  );
};

export default AppLauncher;
