import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'core-js/modules/es7.promise.finally';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ga } from '@rsos/capstone';
import { gspx } from '@rsos/capstone';
import '@rsos/capstone/src/overridePublicPath';
import Layout from '@rsos/components/capstone/Layout';
import { FlagProvider } from '@rsos/flags';
import '@rsos/utils/sentry';
import App, { topLevelRoutes } from './app/App';
import ErrorBoundary from './components/capstoneLegacy/Common/ErrorBoundary';
import './scss/index.scss';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from './store';

if (module.hot) {
  module.hot.accept();
}

ga.initialize(store);
gspx.initialize(store);

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <Provider store={store}>
    <FlagProvider>
      <PersistGate loading={'Loading'} persistor={persistor}>
        <BrowserRouter>
          <Layout
            appPaths={topLevelRoutes.map(route => route.path)}
            persistor={persistor}
          >
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </Layout>
        </BrowserRouter>
      </PersistGate>
    </FlagProvider>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
