import React from 'react';
import { AttentionTriangleIcon } from '@rsos/assets/icons';
import { Modal, Box, Button, Text } from '@rsos/components/capstone/base';
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@rsos/components/capstone/base/Modal/ModalComponents';
import { ButtonContainer, IconWrapper } from './Modal.styles';

const ErrorModal = ({ isOpened, toggleErrorModal }) => {
  return (
    <Modal
      id="position-id-selection-modal"
      isOpened={isOpened}
      onClose={toggleErrorModal}
      width={380}
    >
      <ModalHeader onClose={toggleErrorModal}>
        <Box mx="5px">
          <Box horizontal align="center">
            <IconWrapper mr={2}>
              <AttentionTriangleIcon
                color="lightDanger"
                width={15}
                height={15}
              />
            </IconWrapper>
            <Text size="xlarge" color="primaryText" inline>
              Error: No Position IDs found
            </Text>
          </Box>
          <Text size="small" color="secondaryText" pt="13px">
            There were no position IDs found. If you have just set up this
            feature, please try again after 1-2 hours. If this problem persists,
            contact your representative at RapidSOS.
          </Text>
        </Box>
      </ModalHeader>

      <ModalBody pt="19px" justify="center" noFooter></ModalBody>
      <ModalFooter justify="center">
        <ButtonContainer horizontal>
          <Button grow btnType="primary" onClick={toggleErrorModal}>
            Close
          </Button>
        </ButtonContainer>
      </ModalFooter>
    </Modal>
  );
};

export default ErrorModal;
