export const API_HOST_META_TAG_NAME = 'mco-api-host';
export const PRODUCTION_API_HOST = 'https://api.rapidsos.com';
export const SESSION_AGE = 'session-age';
export const MCO_ENV = 'mco-env';
export const REC_PRODUCT_NAME = 'rec-product-name';

/**
 * Reads the content value of the meta tag with the given 'name' value.
 * If no tag is found, it returns an empty string.
 * @param {string} name - The name for the meta tag
 */
export const readMetaTag = (name: string) => {
  const meta = document.querySelector(`meta[name='${name}']`);
  return (meta && meta.getAttribute('content')) || '';
};

/**
 * Returns the API host corresponding to the environment's stack.
 */
export const getAPIHost = () =>
  readMetaTag(API_HOST_META_TAG_NAME) || PRODUCTION_API_HOST;


/**
 * Returns the auto logged out session age in seconds
 */
export const getSessionAge = () =>
  parseInt(readMetaTag(SESSION_AGE)) || 172800;

/**
 * Returns the environment corresponding to the current stack.
 */
export const getMCOEnvironment = () => readMetaTag(MCO_ENV) || '';

/**
 * Returns the REC product name corresponding to the current environment.
 */
export const getRECProductName = () =>
  readMetaTag(REC_PRODUCT_NAME) || 'REM-Sandbox';