import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxWidth: 19,
  viewboxHeight: 19,
};
const MagnifyingGlassIcon = ({
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => {
  return (
    <SVGLoader
      viewboxHeight={viewboxHeight}
      viewboxWidth={viewboxWidth}
      {...rest}
    >
      <path
        d="M17.57808,15.54552 L14.23368,12.20112 C16.3434495,9.04295852 15.7134764,4.79946066 12.7772207,2.39041234 C9.84096504,-0.0186359827 5.55616096,0.192537369 2.8710156,2.87863222 C0.185870232,5.56472707 -0.0237882382,9.84960554 2.38629803,12.7850093 C4.7963843,15.7204131 9.04010461,16.3488859 12.19752,14.238 L15.54192,17.58168 C16.108234,18.1341008 17.011766,18.1341008 17.57808,17.58168 C18.14023,17.0193602 18.14023,16.1078398 17.57808,15.54552 Z M8.1,2.8836 C10.9829264,2.8836 13.32,5.22067361 13.32,8.1036 C13.32,10.9865264 10.9829264,13.3236 8.1,13.3236 C5.21707361,13.3236 2.88,10.9865264 2.88,8.1036 C2.88317446,5.22198953 5.21838953,2.88677446 8.1,2.8836 Z"
        id="Shape"
        fillRule="nonzero"
      ></path>
    </SVGLoader>
  );
};

export default MagnifyingGlassIcon;
